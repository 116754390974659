'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactabularUtils = require('reactabular-utils');

function resolve(_ref) {
  var columns = _ref.columns;
  var method = _ref.method;

  if (!columns) {
    throw new Error('resolve - Missing columns!');
  }
  if (!method) {
    throw new Error('resolve - Missing method!');
  }

  var resolvedColumns = (0, _reactabularUtils.resolveBodyColumns)(columns);

  return function () {
    var rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return rows.map(function (rowData, rowIndex) {
      var ret = {};

      resolvedColumns.forEach(function (column) {
        var result = method({
          rowData: rowData,
          rowIndex: rowIndex,
          column: column
        });

        delete result.undefined;

        ret = _extends({}, rowData, ret, result);
      });

      return ret;
    });
  };
}

exports.default = resolve;