var React = require('react');

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';

const answerTypes = [];

var MultipleChoice = require('./MultipleChoice.js');
var FreeText = require('./FreeText.js');
var NumericValues = require('./NumericValues.js');
var CheckList = require('./CheckList.js');
var ImageUploadQuestion = require('./ImageUploadQuestion.js');
var ShowImageQuestion = require('./ShowImageQuestion.js');
var FreeNumericValue = require('./FreeNumericValue.js');
var FreeNumber = require('./FreeNumber.js');

class QuestionTypeHandler extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			questionTypeValue: 0,
			values: []
		}
	
	}

	componentDidMount(){
		this.setState({questionTypeValue: this.props.questionTypeValue});
    	this.setState({values: this.props.valuesIn});
  	}

  	componentWillReceiveProps(nextProps) {

  		if (nextProps.questionTypeValue !== this.state.questionTypeValue) {	    	
	      	this.setState({ questionTypeValue: nextProps.questionTypeValue });
    	} 

  	}

  	handleValuesState(valuesIn){
  		this.setState({values: valuesIn});
  		this.props.valuesHandler(valuesIn);
  	}
  	
	
  	render(){
  		const questionTypeValueIn = this.state.questionTypeValue;
		
		let childComponentNode = null;    	

		if(questionTypeValueIn == 0){
			childComponentNode = <MultipleChoice valuesIn={this.props.valuesIn} valuesHandler={this.handleValuesState.bind(this)}/>;
			
		}else if(questionTypeValueIn == 1){			
			childComponentNode = <FreeText valuesIn={this.props.valuesIn} valuesHandler={this.handleValuesState.bind(this)}/>;
			
		}else if(questionTypeValueIn == 2){			
			childComponentNode = <NumericValues valuesIn={this.props.valuesIn} valuesHandler={this.handleValuesState.bind(this)}/>;
			
		}else if(questionTypeValueIn == 3){			
			childComponentNode = <CheckList valuesIn={this.props.valuesIn} valuesHandler={this.handleValuesState.bind(this)}/>;			
			
		}else if(questionTypeValueIn == 4){						
			childComponentNode = <ImageUploadQuestion valuesIn={this.props.valuesIn} valuesHandler={this.handleValuesState.bind(this)}/>;
		}			
		else if(questionTypeValueIn == 5){			
			childComponentNode = <ShowImageQuestion valuesIn={this.props.valuesIn} valuesHandler={this.handleValuesState.bind(this)}/>;			
			
		}		
  		else if(questionTypeValueIn == 6){			
			childComponentNode = <FreeNumber valuesIn={this.props.valuesIn} valuesHandler={this.handleValuesState.bind(this)}/>;			
			
		}
	    return (  
	    	<div>
	    	
		    			{childComponentNode}
		    			
        	</div>
	    	);
  	} 
}

module.exports = QuestionTypeHandler;
