var React = require('react');

var firebase = require('firebase');
var TemplateInfoPreview = require('./TemplateInfoPreview.js');
var QuestionPreview = require('./QuestionPreview.js');

import update from 'react-addons-update';

import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import FlatButton from 'material-ui/FlatButton';


//Icone QuestionTypes
import CheckBoxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank';
import FreeTextIcon from 'material-ui/svg-icons/editor/format-align-left';
import MultipleChoiceIcon from 'material-ui/svg-icons/image/adjust';
import ImagesIcon from 'material-ui/svg-icons/image/image';
import NumberIcon from 'material-ui/svg-icons/image/looks-6';
import RenameIcon from 'material-ui/svg-icons/communication/textsms';



class TemplatePreview extends React.Component {

	constructor(props){
		super(props);    
	    this.state = { 
	    	templateInfo: {},
	    	questionsList: [],
	    	questionTypes: [],
	    	buttonNameState: '',
	    	imageUrl:''   	
	    };
	    
	}	


	componentWillMount(){		 		  		

    	var templateId = this.props.templateId;
    	var mode = this.props.mode;
    	
    	console.log("templateId: ", templateId, "mode: ",mode);

    	this.setTemplateInfoById(templateId);

    	this.setQuestions(templateId);
    	
    	let storageRef = firebase.storage().ref('TemplateImages/' + templateId +'/templateImage.jpg'); 

	    storageRef.getDownloadURL().then(function(url) {
	        this.setState({imageUrl: url});
	      }.bind(this)).catch(function(error) {      
	      	this.setState({imageUrl: null});
	      }.bind(this));
    	
  	}

  	setTemplateInfoById(templateId){
	
		const rootRef = firebase.database().ref('Template/' + templateId);
			
			let stubObj = {};

		    rootRef.once('value', snap => {
		    	    		    	        		        

		        let childKey = snap.key;
	    	    let childData = snap.val();

		        stubObj.id = snap.key;
		        stubObj.name = childData.name;
		        if(childData.img_storage_link){
		        	stubObj.img_storage_link = childData.img_storage_link;
		        }else{
		        	stubObj.img_storage_link = null;
		        }
		        stubObj.owner = childData.owner;
		        stubObj.target_google_group = childData.target_google_group;
		        stubObj.description = childData.description;
		        stubObj.creation_date = childData.creation_date;
		        stubObj.last_update_date = childData.last_update_date;
		        stubObj.start_date = childData.start_date;
		        stubObj.end_date = childData.end_date;
		        stubObj.type = childData.type;
		        stubObj.type_color = childData.type_color;
		        			                          	     
	    	this.setState({templateInfo: stubObj});
    		
	    	});							    
	}

  	setQuestions(templateId, mode){
  	
  		const rootRef = firebase.database().ref('Questions/' + templateId);

	  		rootRef.once('value', snap => {

	  			this.setEntryFromFirebaseEntryToQuestionListArray(snap.val());
	  			
		    });			
  		
  	}

  	setEntryFromFirebaseEntryToQuestionListArray(snapshotData){
    
    	var dataToArray = [];

	    snapshotData.forEach(function(childSnapshotData) {
	        var stubObj = {};
        
	        stubObj.id_type = childSnapshotData.id_type; 
	        stubObj.mandatory = childSnapshotData.mandatory; 
	        stubObj.question = childSnapshotData.question; 
	        stubObj.values = childSnapshotData.values; 	     	
	        dataToArray.push(stubObj);          

	    }.bind(this));      
    
	    
	    this.setState({questionsList: dataToArray});
	 
	}	
	
	duplicateTemplate(){
		this.context.history.push('/template/'+ this.props.templateId + '/duplicate');
	}

	goBack(){
		this.context.router.replace('/dashboard');
	}

  	render(){

	    return (    	    	
	    		<div className="row">
	    		
	    		<div className="col-md-8">	

	    			<TemplateInfoPreview templateInfo={this.state.templateInfo} />

	    			{this.state.questionsList.map ((questionData,index) => 
		    			<QuestionPreview 
		    				questionData={questionData} 		    				
		    				key={index} 
		    				/>)}

				</div>
				<div className="col-md-4">
				<div className="templateInfo-date-float-left">
					<div className="templateInfo-date-float-left">
					<div>
					<FlatButton label="DUPLICA"
		    				onClick={this.duplicateTemplate.bind(this)}
		    				backgroundColor="#fff" />
					
					</div>		    				
		    		<div className="annullaFlatButton">

					<FlatButton label="INDIETRO"
		    				onClick={this.goBack.bind(this)}
		    				backgroundColor="#fff" />

		    		</div>
		    						    		
		    	</div>	
		    	</div>	
		    	</div>
	    		</div>
	    	);
  	} 
}

TemplatePreview.contextTypes = {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
};
module.exports = TemplatePreview;
