'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _isEqual = require('lodash/isEqual');

var _isEqual2 = _interopRequireDefault(_isEqual);

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactabularUtils = require('reactabular-utils');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BodyRow = function (_React$Component) {
  _inherits(BodyRow, _React$Component);

  function BodyRow() {
    _classCallCheck(this, BodyRow);

    return _possibleConstructorReturn(this, (BodyRow.__proto__ || Object.getPrototypeOf(BodyRow)).apply(this, arguments));
  }

  _createClass(BodyRow, [{
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps) {
      // eslint-disable-line no-unused-vars
      var previousProps = this.props;

      // Check for row based override.
      var components = nextProps.components;


      if (components && components.row && components.row.shouldComponentUpdate) {
        if ((0, _isFunction2.default)(components.row.shouldComponentUpdate)) {
          return components.row.shouldComponentUpdate.call(this, nextProps);
        }

        return true;
      }

      return !((0, _isEqual2.default)(previousProps.columns, nextProps.columns) && (0, _isEqual2.default)(previousProps.rowData, nextProps.rowData));
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props;
      var columns = _props.columns;
      var components = _props.components;
      var onRow = _props.onRow;
      var rowKey = _props.rowKey;
      var rowIndex = _props.rowIndex;
      var rowData = _props.rowData;


      return _react2.default.createElement(components.row, onRow(rowData, { rowIndex: rowIndex, rowKey: rowKey }), columns.map(function (_ref, j) {
        var column = _ref.column;
        var cell = _ref.cell;
        var property = _ref.property;
        var props = _ref.props;

        var _ref2 = cell || {};

        var _ref2$transforms = _ref2.transforms;
        var transforms = _ref2$transforms === undefined ? [] : _ref2$transforms;
        var _ref2$format = _ref2.format;
        var format = _ref2$format === undefined ? function (a) {
          return a;
        } : _ref2$format; // TODO: test against this case

        var extraParameters = {
          columnIndex: j,
          column: column,
          rowData: rowData,
          rowIndex: rowIndex,
          rowKey: rowKey,
          property: property
        };
        var transformed = (0, _reactabularUtils.evaluateTransforms)(transforms, rowData[property], extraParameters);

        if (!transformed) {
          console.warn('Table.Body - Failed to receive a transformed result'); // eslint-disable-line max-len, no-console
        }

        return _react2.default.createElement(components.cell, _extends({
          key: j + '-cell'
        }, (0, _reactabularUtils.mergePropPair)(props, transformed)), transformed.children || format(rowData['_' + property] || rowData[property], extraParameters));
      }));
    }
  }]);

  return BodyRow;
}(_react2.default.Component);

BodyRow.defaultProps = _types.tableBodyRowDefaults;
process.env.NODE_ENV !== "production" ? BodyRow.propTypes = _types.tableBodyRowTypes : void 0;

exports.default = BodyRow;