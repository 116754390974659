'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function byFunction(path) {
  return function (_ref) {
    var rowData = _ref.rowData;
    var _ref$column = _ref.column;
    var column = _ref$column === undefined ? {} : _ref$column;
    var property = column.property;


    if (!property) {
      return rowData;
    }

    var value = rowData[property];
    var resolver = (0, _get2.default)(column, path);
    var ret = _extends({}, rowData, _defineProperty({}, property, value));

    if (resolver) {
      ret['_' + property] = resolver(value, {
        property: property,
        rowData: rowData
      });
    }

    return ret;
  };
}

exports.default = byFunction;