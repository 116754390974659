import React from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { browserHistory } from 'react-router'

var CreateTemplateButton = React.createClass ({

  contextTypes: {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
  },

  createTemplate: function(){
    //this.context.router.replace('/template/new/create');
    this.context.history.push('/template/new/create');
  },

  render: function(){

    return (  
      <div>

        <FloatingActionButton backgroundColor="black" onClick={this.createTemplate}>
                      <ContentAdd />
        </FloatingActionButton>
           
      </div>
      )
    }
})

export default CreateTemplateButton;