var React = require('react');
var ReactFireMixin = require('reactfire');
var firebase = require('firebase');

var fakeList = [
  {
    owner:'test1@vs.it',
    note:'note 123',
    name:'Template test 1',
    description:'desc for template test 1',
    creation_date: {},
    last_update_date:{},
    start_date:{},
    end_date:{},
    target_google_group:'group1@ovs.it',
    image: 'url/123.jpg'
  },
  {
    owner:'test2@vs.it',
    note:'note 234',
    name:'Template test 2',
    description:'desc for template test 2',
    creation_date: {},
    last_update_date:{},
    start_date:{},
    end_date:{},
    target_google_group:'group1@ovs.it',
    image: 'url/234.jpg'
  },
  {
    owner:'test1@vs.it',
    note:'note 234',
    name:'Template test 3',
    description:'desc for template test 3',
    creation_date: {},
    last_update_date:{},
    start_date:{},
    end_date:{},
    target_google_group:'group1@ovs.it',
    image: 'url/123.jpg'
  }
];

var InitialImport = React.createClass({

  mixins: [ReactFireMixin],

  submitTemplate: function() {

    for(var k in fakeList){
      this.firebaseRefs['data'].push(fakeList[k]);      
    }
    
  },

  getInitialState: function() {
    return {
      data: []
    };
  },

  initializaDataArray: function(){
    for(var k in fakeList){
      fakeList[k].creation_date = (new Date()).getTime();
      fakeList[k].last_update_date = (new Date()).getTime();
      fakeList[k].start_date = (new Date()).getTime();
      fakeList[k].end_date = (new Date("October 30, 2017 11:13:00")).getTime();    
    }
  },
  componentWillMount: function() {
    this.initializaDataArray();
    this.bindAsArray(firebase.database().ref('Template'), 'data');
  },


  render: function() {
    return (
      <div>
        
        <input type="button" value="initialize" onClick={this.submitTemplate} />
      </div>
    );
  }
});

module.exports = InitialImport;