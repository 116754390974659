var React = require('react');

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';

const answerTypes = [];

class FreeNumber extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			questionListValues: []
		}
	
	}

	componentWillMount(){
    	this.setState({questionListValues: this.props.valuesIn});
  	}

  	handleTextFieldChange(newVal){
  	
  		var newArray = this.state.questionListValues;
  		newArray[0] = newVal.target.value;
  		
  		this.setState({questionListValues: newArray});  
  		
  		//Settare lo stato del padre
  		this.props.valuesHandler(newArray);	  		
  		
  	}
  	
	
  	render(){

	    return (  
	    	<div>
	    	
		    		<TextField type="text" name="FreeTextField"
		    			value={this.state.questionListValues[0]}
		    			fullWidth={true} multiLine={true} 
		    			onChange={(newVal) => {
		    				/*
		    					newTemplateInfo.name = newVal.target.value;
		    					this.props.changeHandler(newTemplateInfo);
		    				*/	

		    					this.handleTextFieldChange(newVal);
		    				}
		    			}
		    			/>
		    			
		    	
        	</div>
	    	);
  	} 
}

module.exports = FreeNumber;
