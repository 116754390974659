'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAddonsShallowCompare = require('react-addons-shallow-compare');

var _reactAddonsShallowCompare2 = _interopRequireDefault(_reactAddonsShallowCompare);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function on(target, eventName, callback, capture) {
  if (target.addEventListener) {
    target.addEventListener(eventName, callback, capture);
  } else if (target.attachEvent) {
    // IE8+ Support
    target.attachEvent('on' + eventName, function () {
      callback.call(target);
    });
  }
}

function off(target, eventName, callback, capture) {
  if (target.removeEventListener) {
    target.removeEventListener(eventName, callback, capture);
  } else if (target.detachEvent) {
    // IE8+ Support
    target.detachEvent('on' + eventName, callback);
  }
}

var state = {};

function forEachListener(props, iteratee) {
  for (var name in props) {
    if (name.substring(0, 2) === 'on' && props[name] instanceof Function) {
      var _capture = name.substr(-7).toLowerCase() === 'capture';

      var _eventName = name.substring(2).toLowerCase();
      _eventName = _capture ? _eventName.substring(0, _eventName.length - 7) : _eventName;

      iteratee(_eventName, props[name], _capture);
    }
  }
}

var EventListener = function (_Component) {
  _inherits(EventListener, _Component);

  function EventListener() {
    _classCallCheck(this, EventListener);

    return _possibleConstructorReturn(this, (EventListener.__proto__ || Object.getPrototypeOf(EventListener)).apply(this, arguments));
  }

  _createClass(EventListener, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.addListeners();
    }
  }, {
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps) {
      return (0, _reactAddonsShallowCompare2.default)({
        props: this.props,
        state: state
      }, nextProps, state);
    }
  }, {
    key: 'componentWillUpdate',
    value: function componentWillUpdate() {
      this.removeListeners();
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      this.addListeners();
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.removeListeners();
    }
  }, {
    key: 'addListeners',
    value: function addListeners() {
      var target = this.props.target;


      if (target) {
        var element = target;

        if (typeof target === 'string') {
          element = window[target];
        }

        forEachListener(this.props, on.bind(null, element));
      }
    }
  }, {
    key: 'removeListeners',
    value: function removeListeners() {
      var target = this.props.target;


      if (target) {
        var element = target;

        if (typeof target === 'string') {
          element = window[target];
        }

        forEachListener(this.props, off.bind(null, element));
      }
    }
  }, {
    key: 'render',
    value: function render() {
      return this.props.children || null;
    }
  }]);

  return EventListener;
}(_react.Component);

EventListener.propTypes = {
  /**
   * You can provide a children too.
   */
  children: _react.PropTypes.node,
  /**
   * The DOM target to listen to.
   */
  target: _react.PropTypes.oneOfType([_react.PropTypes.object, _react.PropTypes.string])
};
exports.default = EventListener;