import React from 'react';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton/IconButton';

import RenameIcon from 'material-ui/svg-icons/communication/textsms';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';

import ExitIcon from 'material-ui/svg-icons/action/exit-to-app';
import GavelIcon from 'material-ui/svg-icons/action/gavel';
import NotificationIcon from 'material-ui/svg-icons/social/notifications';

import * as firebase from 'firebase';

import Avatar from 'material-ui/Avatar';

import { browserHistory } from 'react-router';

var IconHeaderUserMenu = React.createClass ({

  contextTypes: {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
  },

  getInitialState: function(){
        return {
            userData: null
        };
  },

  componentWillMount: function() {
    
    var self = this; 

    let userDataIn = firebase.auth().currentUser;
    
    this.setState({userData: userDataIn});

  },

  render: function(){

    return (  
      <div>    
                  
         <div className="admin-menu">    
        <div className="admin-name text-right">
          <span className="header-user-name">{this.state.userData.displayName}</span><br /> {this.state.userData.email}</div>  
        <div>     
                     
        </div>
                  
        <div className="admin-icon-menu">      
            <IconMenu      
              iconButtonElement={<Avatar src={this.state.userData.photoURL} size={65}></Avatar>}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
              targetOrigin={{horizontal: 'right', vertical: 'top'}}
              onItemTouchTap ={ (event,child) => {

                    if(child.key === "1"){
                           
                      this.context.history.push('/privacy');                  
                    }else if(child.key === "2"){
                      
                      this.context.history.push('/notifications');                    
                    }else if(child.key === "3"){
                      
                      firebase.auth().signOut();                    
                    } 

                  } 
               } 
              >
              
                <MenuItem primaryText="Privacy" leftIcon={<GavelIcon />} key="1" />
                <MenuItem primaryText="Notifications" leftIcon={<NotificationIcon />} key="2" />
                <MenuItem primaryText="Logout" leftIcon={<ExitIcon />} key="3"/>              

            </IconMenu>  
          </div> 
        </div> 
      </div>
      );
  }
});

export default IconHeaderUserMenu;