var React = require('react');

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';

const answerTypes = [];

class FreeNumericValue extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			questionListValues: []
		}
	
	}

	componentWillMount(){	
	  		
  		this.setState({questionListValues: this.props.valuesIn});  
  		
  	}

  	handleTextFieldChange(newVal,index){
  		
  		//gestione array  		  
  		var newArray = this.state.questionListValues;

  		if(parseInt(newVal.target.value)){

  			newArray[index] = parseInt(newVal.target.value);
  		
  			this.setState({questionListValues: newArray});  
  		
  		//Settare lo stato el padre
  			this.props.valuesHandler(newArray);	  
  		
  		}else{

  			newArray[index] = 0;
  		
  			this.setState({questionListValues: newArray});  
  		
  		//Settare lo stato el padre
  			this.props.valuesHandler(newArray);	  
  		}
  		
  	}
  	
	
  	render(){

	    return (  
	    	<div>
	    			<div className="">
		    			<TextField type="text"
			    			floatingLabelText="Valore Numerico"
			    			value={this.state.questionListValues[0]}
			    			name={"MinImageField0"}
			    			onChange={(newVal) => {
			    					
			    					this.handleTextFieldChange(newVal,0);

			    				}
			    			}
		    			/>
		    					    			

		    		</div>
		    	
        	</div>
	    	);
  	} 
}

module.exports = FreeNumericValue;
