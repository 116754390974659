var React = require('react');
var ReactFireMixin = require('reactfire');
var firebase = require('firebase');

var fakeList = [
  {
    id_type:'0',
    question:'domanda test 1',
    values:['d1','d2','d3'],
    mandatory: false    
  },
  {
    id_type:'1',
    question:'domanda test 2',
    values:['ma che bel castello'],
    mandatory: false 
  },
  {
    id_type:'2',
    question:'domanda test 2',
    values:['ma che bel castello'],
    mandatory: false 
  },
  {
    id_type:'3',
    question:'domanda test 2',
    values:['c1','c2','c3'],
    mandatory: false 
  },
  {
    id_type:'4',
    question:'domanda test 2',
    values:['3','6'],
    mandatory: false 
  }    
];

var InitialImportQuestions = React.createClass({

  mixins: [ReactFireMixin],

  submitTemplate: function() {

    for(var k in fakeList){
      this.firebaseRefs['data'].push(fakeList[k]);      
    }
    
  },

  getInitialState: function() {
    return {
      data: []
    };
  },

  componentWillMount: function() {
    
    this.bindAsArray(firebase.database().ref('Questions/-KUgrZm9b5qFjQRPR0UE'), 'data');
  },


  render: function() {
    return (
      <div>
        
        <input type="button" value="initialize" onClick={this.submitTemplate} />
      </div>
    );
  }
});

module.exports = InitialImportQuestions;