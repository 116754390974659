'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _column_matches = require('./_column_matches');

var _column_matches2 = _interopRequireDefault(_column_matches);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var singleColumn = function singleColumn(_ref) {
  var castingStrategy = _ref.castingStrategy;
  var columns = _ref.columns;
  var _ref$searchColumn = _ref.searchColumn;
  var searchColumn = _ref$searchColumn === undefined ? 'all' : _ref$searchColumn;
  var query = _ref.query;
  var strategy = _ref.strategy;
  var transform = _ref.transform;
  return function (rows) {
    if (!query) {
      return rows;
    }

    var ret = columns;

    if (searchColumn !== 'all') {
      ret = columns.filter(function (col) {
        return col && col.property === searchColumn;
      });
    }

    return rows.filter(function (row) {
      return ret.filter(function (column) {
        return (0, _column_matches2.default)({
          query: query, castingStrategy: castingStrategy, column: column, strategy: strategy, transform: transform, row: row
        });
      }).length > 0;
    });
  };
};

exports.default = singleColumn;