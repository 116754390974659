var React = require('react');
var ReactDOM = require('react-dom');

import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import DuplicateIcon from 'material-ui/svg-icons/image/filter-none';
import Toggle from 'material-ui/Toggle';

import CheckBoxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank';
import FreeTextIcon from 'material-ui/svg-icons/editor/format-align-left';
import MultipleChoiceIcon from 'material-ui/svg-icons/image/adjust';
import ImagesIcon from 'material-ui/svg-icons/image/image';
import NumberIcon from 'material-ui/svg-icons/image/looks-6';
import RenameIcon from 'material-ui/svg-icons/communication/textsms';


var QuestionTypeHandler = require('./QuestionTypeHandler.js');

const answerTypes = [];

class Question extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			questionType: null, //idType su firebase
			question:'',
			prev_question_text:'',
			prev_question_type:null,
			values: [], //values su firebase
			mandatory: false			
		};

	}
	
	prev_question_text:''
	prev_question_type:null

	componentWillMount(){
		this.setState({question: this.props.questionData.question});
		this.setState({questionType: this.props.questionData.questionType});
		this.setState({values: this.props.questionData.values});
		this.setState({mandatory: this.props.questionData.mandatory});
		//control prev state
		this.prev_question_text = this.props.questionData.prev_question_text;
		this.prev_question_type = this.props.questionData.prev_question_type;
	}

  	handleQuestionTypeChange(event, index, value){
  		this.setState({values: []}); 

  		this.prev_question_type = this.state.questionType;
  		if(this.state.question !== 'Testo Libero' && this.state.question !== 'Risposta Numerica'){
  			this.prev_question_text = this.state.question;		
  		}  		
  		
  		this.setState({questionType: value});
  		console.log(this.state.questionType, this.prev_question_text,this.state.question);

  		//free text as 1 change state var for display disabled text field
  		if(value==='1'){
  			this.setState({question: 'Testo Libero'});
  			this.props.questionNameChangeHandler(this.props.questionIdInList,'Testo Libero');	
  		}else if(value==='6'){
  			this.setState({question: 'Risposta Numerica'});
  			this.props.questionNameChangeHandler(this.props.questionIdInList,'Risposta Numerica');	
  		}else{
  			
  			if(this.prev_question_type === '1' || this.prev_question_type === '6'){
  				this.setState({question: this.prev_question_text});	
  				//this.setState({});
  				this.props.questionNameChangeHandler(this.props.questionIdInList,this.prev_question_text);
  			}else{
  				this.props.questionNameChangeHandler(this.props.questionIdInList,this.state.question);
  			}
  			
  		}
  		
  		this.props.questionTypeChangeHandler(this.props.questionIdInList,value);
  	}

  	handleQuestionValues(valuesIn){  		
  		this.setState({values: valuesIn});  		
  		this.props.questionValuesChangeHandler(this.props.questionIdInList,valuesIn);
  	}

  	handleMandatory(event, toggle){
  		
  		this.setState({mandatory: toggle});
  		console.log(this.state.mandatory);
  		this.props.questionMandatoryChangeHandler(this.props.questionIdInList,toggle);
  	}

  	handleQuestionValChange(val){
  		this.setState({question: val.target.value});  		
  		this.props.questionNameChangeHandler(this.props.questionIdInList,val.target.value);
  	}

  	handleRemoveQuestion(){
  		
  		this.props.questionRemoveHandler(this.props.questionIdInList);
  	}

  	handleDuplicateQuestion(){  		  	

  		this.props.questionDuplicateHandler(this.state);	
  	}


  	render(){

  		let questionTitle = null;
  		//free text as 1 change state var for display disabled text field
  		if(this.state.questionType !== '6'){

  			questionTitle = <TextField type="text" id={"QuestionNameTextField" +this.props.questionIdInList}
		    			value={this.state.question} 
		    			multiLine={true}
		    			fullWidth={true} 
		    			rows={1}
      					rowsMax={4}
		    			onChange={(newVal) => {
		    						    					
		    					this.handleQuestionValChange(newVal);
		    				
		    				}
		    			}
		    		/>;
	
  		}else if(this.state.questionType === '6'){
  			questionTitle = <TextField type="text" id={"QuestionNameTextField" +this.props.questionIdInList}
		    			value={this.state.question} 
		    			disabled={true}
		    		/>;
  		}
			
			

	    return (  
	    	<div className="single-question" id={this.props.questionIdInList}>

		    	<Card>  

		    		<CardText>	
		    		
			    		<div className="pull-right custom-selectBox">
			    			<SelectField
								value={this.state.questionType}																													
								style={{
							      backgroundColor: '#ddd'							      
							    }}								  
							    labelStyle={{
							    	'margin-left':'20px'						    		
									}}	
						        onChange={this.handleQuestionTypeChange.bind(this)}>

						          { this.props.questionTypes.map ( (questionType,index) => 
						          	 <MenuItem leftIcon={questionType.leftIcon} 
						          		value={questionType.key} 
						          		primaryText={questionType.name} 
						          		key={questionType.key} /> ) }
						         						         
						    </SelectField>
			    		</div>
		    		</CardText>
		    		<CardText>
		    			
		    		{questionTitle}
		    					    		
		    		<QuestionTypeHandler 
		    			questionTypeValue={this.state.questionType} 
		    			valuesIn={this.state.values}
		    			valuesHandler={this.handleQuestionValues.bind(this)}/>	    
		    		
	        		</CardText>

	        		<CardText>

	        			 <FlatButton onClick={this.handleDuplicateQuestion.bind(this)}>
	        			 	<DuplicateIcon />
	        			 </FlatButton>

	        			 <FlatButton onClick={this.handleRemoveQuestion.bind(this)}>
	        			 	<DeleteIcon />
	        			 </FlatButton>	   

	        			 <div className="pull-right">
	        			 	<Toggle
					            toggled={this.state.mandatory}
					            onToggle={this.handleMandatory.bind(this)}
					            labelPosition="left"
					            label="Obbligatorio" />
	        			 </div>

	        		</CardText>

	        			
	        		<br />
	        		<br />
	        		}
	        	</Card>	
        	</div>
	    	);
  	} 
}

module.exports = Question;
