"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function index(_ref) {
  var rowData = _ref.rowData;
  var rowIndex = _ref.rowIndex;

  return _extends({}, rowData, {
    _index: rowIndex
  });
}

exports.default = index;