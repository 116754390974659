'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var infix = function infix(queryTerm) {
  return {
    evaluate: function evaluate() {
      var _this = this;

      var searchText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (!searchText) {
        return false;
      }

      if (Array.isArray(searchText)) {
        return searchText.some(function (v) {
          return _this.evaluate(v);
        });
      }

      return searchText.indexOf(queryTerm) !== -1;
    },
    matches: function matches() {
      var _this2 = this;

      var searchText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (!searchText) {
        return [];
      }

      if (Array.isArray(searchText)) {
        return searchText.reduce(function (result, text, index) {
          var search = _this2.matches(text);

          if (search.length) {
            result[index] = search; // eslint-disable-line no-param-reassign
          }

          return result;
        }, new Array(searchText.length));
      }

      var splitString = searchText.split(queryTerm);
      var result = [];
      var currentPosition = 0;

      for (var x = 0; x < splitString.length; x += 1) {
        result.push({
          startIndex: currentPosition + splitString[x].length,
          length: queryTerm.length
        });

        currentPosition += splitString[x].length + queryTerm.length;
      }

      result.pop();

      return result;
    }
  };
};

var prefix = function prefix(queryTerm) {
  return {
    evaluate: function evaluate() {
      var _this3 = this;

      var searchText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (!searchText) {
        return false;
      }

      if (Array.isArray(searchText)) {
        return searchText.some(function (v) {
          return _this3.evaluate(v);
        });
      }

      return searchText.indexOf(queryTerm) === 0;
    },
    matches: function matches() {
      var _this4 = this;

      var searchText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (!searchText) {
        return [];
      }

      if (Array.isArray(searchText)) {
        return searchText.reduce(function (result, text, index) {
          var search = _this4.matches(text);

          if (search.length) {
            result[index] = search; // eslint-disable-line no-param-reassign
          }

          return result;
        }, new Array(searchText.length));
      }

      var prefixIndex = searchText.indexOf(queryTerm);

      if (prefixIndex === 0) {
        return [{
          startIndex: 0,
          length: queryTerm.length
        }];
      }

      return [];
    }
  };
};

exports.default = {
  infix: infix,
  prefix: prefix
};