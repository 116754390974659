var React = require('react');

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';

const answerTypes = [];

class ImageUploadQuestion extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			questionListValues: []
		}
	
	}

	componentWillMount(){	
	  		
  		this.setState({questionListValues: this.props.valuesIn});  
  		
  	}

  	handleTextFieldChange(newVal,index){
  		
  		//gestione array  		  
  		var newArray = this.state.questionListValues;
  		newArray[index] = newVal.target.value;
  		
  		this.setState({questionListValues: newArray});  
  		
  		//Settare lo stato del padre
  		this.props.valuesHandler(newArray);	  		
  		
  	}
  	
	
  	render(){

	    return (  
	    	<div>
	    			<div className="">
		    			<TextField type="text"
		    			floatingLabelText="Numero minimo"
		    			value={this.state.questionListValues[0]}
		    			name={"MinImageField0"}
		    			onChange={(newVal) => {
		    			
		    					this.handleTextFieldChange(newVal,0);

		    				}
		    			}
		    			/>
		    			
		    			<TextField type="text"
		    			floatingLabelText="Numero massimo"
		    			value={this.state.questionListValues[1]}
		    			name={"MinImageField1"}
		    			className="pull-right"
		    			onChange={(newVal) => {
		    			
		    					this.handleTextFieldChange(newVal,1);
		    				}
		    			}
		    			/>

		    		</div>
		    	
        	</div>
	    	);
  	} 
}

module.exports = ImageUploadQuestion;
