import React from 'react';

import ovsBaseTheme from 'material-ui/styles/baseThemes/ovsBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import injectTapEventPlugin from 'react-tap-event-plugin';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';


/**
 * A modal dialog can only be closed by selecting one of the actions.
 */

class GenericDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: this.props.stateOpen
    }

  }

  componentWillMount() {}

  handleOk() {

    this.setState({open: false});

  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.stateOpen !== this.state.open) {
      this.setState({open: nextProps.stateOpen});
    }
  }

  render() {
    const actions = [ < FlatButton label = "Ok" onTouchTap = {
        this
          .handleOk
          .bind(this)
      } />
    ];

    return (

      <div>
        <MuiThemeProvider muiTheme={getMuiTheme(ovsBaseTheme)}>
          <div>
            <Dialog
              title={this.props.notification_title}
              actions={actions}
              modal={true}
              open={this.state.open}>

              <p>{this.props.notification_text}</p>

            </Dialog>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

module.exports = GenericDialog;