import React from 'react';

import ovsBaseTheme from 'material-ui/styles/baseThemes/ovsBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import injectTapEventPlugin from 'react-tap-event-plugin';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

import Q from 'q';

var firebase = require('firebase');

var ReactToastr = require("react-toastr");
var {ToastContainer} = ReactToastr;
var ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);


/**
 * A modal dialog can only be closed by selecting one of the actions.
 */

class NotificationDialog extends React.Component {

  constructor(props){
      super(props);    
      
      this.state = { 
        open: true,          
        notification_title:'',
        notification_text: ''
      }      
      
  }  

  mailUser: {}

  userFullName:{}

  componentWillMount(){       

      let userData = firebase.auth().currentUser;
      this.mailUser = userData.email;
      this.userFullName = userData.displayName;

  }

  handleAction() {    

    let self = this;

    this.setState({open: false});

    var stubObj = {
      mail: this.props.targetGoogleGroup,
      message: this.state.notification_text,
      timestamp: (new Date()).getTime(),
      owner: this.mailUser,
      title: this.state.notification_title,
      type:'push_notification'
    };

    this.createNotificationInstanceOnFirebase(stubObj).then(function(res){
        self.addSuccessMsg(res);  
    }).catch(function(err){
        self.addAlert(err);  
    });

  }

  addAlert(msgsIn){ 
    var self = this;  
    
    (function myLoop (i) {          
       setTimeout(function () {   
          self.refs.container.error(
            msgsIn[i-1],
            "Error", {
            timeOut: 30000,
            extendedTimeOut: 10000
          }); 

          if (--i) myLoop(i);
       }, 500)
    })(msgsIn.length);  
            
  }

  addSuccessMsg(msgsIn){    
    console.log("zzz");
    this.refs.container.success(
        msgsIn,
        "Success", {
        timeOut: 30000,
        extendedTimeOut: 10000
      }); 
            
  }



  createNotificationInstanceOnFirebase(objectToInsert){
      
      var deferredCreation = Q.defer();

      const notificationRef = firebase.database().ref('notification');

      notificationRef.push(objectToInsert).then(function(res){
        
        deferredCreation.resolve("Notification Created!");
      }).catch(function(err){
        
        deferredCreation.reject("Error");
      })

      return deferredCreation.promise;
  }

  handleClose() {    
    this.setState({open: false});    
  }

 componentWillReceiveProps(nextProps) {
  
  if (nextProps.stateOpen !== this.state.open) {
      this.setState({ open: true });
      this.setState({ notification_text: '' });
      this.setState({ notification_title: '' });
    } 
  }
  
  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        onTouchTap={this.handleClose.bind(this)}
      />,
      <FlatButton
        label="Invia"            
        onTouchTap={this.handleAction.bind(this)}
      />
    ];

    return (

      <div>
        <MuiThemeProvider muiTheme={getMuiTheme(ovsBaseTheme)}>              
        <div>
        <ToastContainer ref="container"
            toastMessageFactory={ToastMessageFactory}
            className="toast-top-right" />

          <Dialog
            title="Invia Notifica"
            actions={actions}         
            modal={true}
            open={this.state.open} >
            <TextField       
                hintText="Notification Title"  
                id="textFieldNotificationTitle"  
                value={this.state.notification_title} 
                  fullWidth={true} 
                  onChange={(newVal) => {

                      this.setState({notification_title: newVal.target.value});
                      
                    }
                  }
                />
            <TextField       
              hintText="Notification Text"  
              id="textFieldNotificationText"  
              value={this.state.notification_text} 
                fullWidth={true} 
                onChange={(newVal) => {

                    this.setState({notification_text: newVal.target.value});
                    
                  }
                }
              />
          </Dialog>
          </div>
        </MuiThemeProvider>  
      </div>
    );
  }
}

module.exports = NotificationDialog;