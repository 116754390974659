import React from 'react';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton/IconButton';
import Download from 'material-ui/svg-icons/file/file-download';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import DuplicateIcon from 'material-ui/svg-icons/image/filter-none';
import NotificationIcon from 'material-ui/svg-icons/social/notifications';
import RenameIcon from 'material-ui/svg-icons/communication/textsms';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';


import DeleteDialog from './DeleteDialog';
import RenameDialog from './RenameDialog';
import NotificationDialog from './NotificationDialog';
import { browserHistory } from 'react-router'
var ReactDOM = require('react-dom');

var IconMenuDashboard = React.createClass ({

  contextTypes: {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
  },

  render: function(){

  return (  
    <div>
      <IconMenu      
        iconButtonElement={<IconButton><MoreVertIcon /></IconButton>}
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        targetOrigin={{horizontal: 'left', vertical: 'top'}} 
        onItemTouchTap ={ (event,child) => {

              if(child.key === "1"){

                console.log("Rinomina",this.props.idItem);
                //popup
                ReactDOM.render(<RenameDialog idRowElement={this.props.idItem} 
                  nameRowElement={this.props.nameItem}
                  stateOpen={true}/>, document.getElementById('ModalDivUsers'));

              }else if(child.key === "2"){

                console.log("Rimuovi",this.props.idItem);
                //popup
                ReactDOM.render(<DeleteDialog idRowElement={this.props.idItem} 
                  nameRowElement={this.props.nameItem} templateImgLink={this.props.templateImgLink}
                  stateOpen={true}/>, 
                  document.getElementById('ModalDivUsers'));

              }else if(child.key === "3"){

                console.log("Duplica",this.props.idItem);
                //Link pagina template                                
                 this.context.history.push('/template/'+ this.props.idItem + '/duplicate');

              }else if(child.key === "4"){

                console.log("Notifica",this.props.idItem);
                ReactDOM.render(<NotificationDialog stateOpen={true} targetGoogleGroup={this.props.targetGoogleGroup} />, 
                  document.getElementById('ModalDivUsers'));

              }           
            } 
         } 
        >
        
          <MenuItem primaryText="Rinomina" leftIcon={<RenameIcon />} key="1" />
          <MenuItem primaryText="Rimuovi" leftIcon={<DeleteIcon />} key="2"/>
          <MenuItem primaryText="Duplica" leftIcon={<DuplicateIcon />} key="3"/>      
          <MenuItem primaryText="Notifica" leftIcon={<NotificationIcon />} key="4"/>      

      </IconMenu>    
    </div>
    );
  }
});

export default IconMenuDashboard;