'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var SearchOptions = function SearchOptions(_ref) {
  var columns = _ref.columns;
  var i18n = _ref.i18n;
  var _ref$onChange = _ref.onChange;
  var onChange = _ref$onChange === undefined ? function () {} : _ref$onChange;
  var value = _ref.value;

  var props = _objectWithoutProperties(_ref, ['columns', 'i18n', 'onChange', 'value']);

  return columns.length ? _react2.default.createElement(
    'select',
    _extends({ onChange: onChange, value: value }, props),
    getOptions(columns, i18n).map(function (_ref2) {
      var name = _ref2.name;
      var value = _ref2.value;
      return (// eslint-disable-line no-shadow, max-len
        _react2.default.createElement(
          'option',
          { key: value + '-option', value: value },
          name
        )
      );
    })
  ) : null;
};
SearchOptions.propTypes = {
  columns: _react2.default.PropTypes.array,
  i18n: _react2.default.PropTypes.object,
  onChange: _react2.default.PropTypes.func,
  value: _react2.default.PropTypes.any
};

var getOptions = function getOptions(columns, i18n) {
  return (columns.length > 1 ? [{
    value: 'all',
    name: i18n.all
  }] : []).concat(columns.map(function (column) {
    if (column.property && column.header && column.header.label) {
      return {
        value: column.property,
        name: column.header.label
      };
    }

    return null;
  }).filter(function (column) {
    return column;
  }));
};

exports.default = SearchOptions;