'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function resolveRowKey(_ref) {
  var rowData = _ref.rowData;
  var rowIndex = _ref.rowIndex;
  var rowKey = _ref.rowKey;

  if (typeof rowKey === 'function') {
    return rowKey({ rowData: rowData, rowIndex: rowIndex }) + '-row';
  } else if (process.env.NODE_ENV !== 'production') {
    // Arrays cannot have rowKeys by definition so we have to go by index there.
    if (!Array.isArray(rowData) && !{}.hasOwnProperty.call(rowData, rowKey)) {
      console.warn( // eslint-disable-line no-console
      'Table.Body - Missing valid rowKey!', rowData, rowKey);
    }
  }

  return (rowData[rowKey] || rowIndex) + '-row';
}

exports.default = resolveRowKey;