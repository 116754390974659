'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _strategies = require('./strategies');

var _strategies2 = _interopRequireDefault(_strategies);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// sorter === lodash orderBy
// https://lodash.com/docs#orderBy
var sorter = function sorter() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var columns = _ref.columns;
  var sortingColumns = _ref.sortingColumns;
  var sort = _ref.sort;
  var _ref$strategy = _ref.strategy;
  var strategy = _ref$strategy === undefined ? _strategies2.default.byIndex : _ref$strategy;
  return function (data) {
    if (!columns) {
      throw new Error('sort.sorter - Missing columns!');
    }

    if (!sortingColumns) {
      return data;
    }

    var columnIndexList = new Array(sortingColumns.length);
    var orderList = new Array(sortingColumns.length);

    Object.keys(sortingColumns).forEach(function (sortingColumnKey) {
      var realColumn = strategy.getColumn(columns, sortingColumnKey) || {};
      var sortingColumn = sortingColumns[sortingColumnKey];

      columnIndexList[sortingColumn.position] = function (row) {
        var property = realColumn.property;
        var value = row[property];
        // Pick resolved value by convention
        var resolvedValue = row['_' + property] || value;

        if (resolvedValue && resolvedValue.toLowerCase) {
          return resolvedValue.toLowerCase();
        }

        if (value && value.toLowerCase) {
          return value.toLowerCase();
        }

        return value;
      };

      orderList[sortingColumn.position] = sortingColumn.direction;
    });

    return sort(data, columnIndexList, orderList);
  };
};

exports.default = sorter;