'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* eslint-disable react/prop-types */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _strategies = require('./strategies');

var _strategies2 = _interopRequireDefault(_strategies);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var defaultStyles = {
  container: {},
  value: {},
  order: {}
};

var header = function header(_ref) {
  var sortable = _ref.sortable;
  var getSortingColumns = _ref.getSortingColumns;
  var _ref$props = _ref.props;
  var props = _ref$props === undefined ? {
    container: {},
    value: {},
    order: {}
  } : _ref$props;
  var _ref$strategy = _ref.strategy;
  var strategy = _ref$strategy === undefined ? _strategies2.default.byIndex : _ref$strategy;

  if (!sortable) {
    throw new Error('header - Missing sortable!');
  }
  if (!getSortingColumns) {
    throw new Error('header - Missing getSortingColumns!');
  }

  return function (value, extra) {
    var sortingColumns = getSortingColumns();
    var sortingColumn = sortingColumns && sortingColumns[extra[strategy.fieldName]] || {};
    var sortingPosition = sortingColumn.position;

    return _react2.default.createElement(
      'div',
      _extends({
        className: 'sort-container',
        style: defaultStyles.container
      }, props.container),
      _react2.default.createElement(
        'span',
        _extends({
          className: 'sort-value',
          style: defaultStyles.value
        }, props.value),
        value
      ),
      {}.hasOwnProperty.call(sortingColumn, 'position') ? _react2.default.createElement(
        'span',
        _extends({
          className: 'sort-order',
          style: defaultStyles.order
        }, props.order),
        sortingPosition + 1
      ) : null,
      _react2.default.createElement('span', sortable(value, extra))
    );
  };
};

exports.default = header;