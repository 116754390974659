var React = require('react');
import { Table, search, Search, sort } from 'reactabular';
import IconMenuDashboard from './IconMenuDashboard';
import ReactFireMixin from 'reactfire'
import reactMixin from 'react-mixin'
import ReactTime from 'react-time'
import { browserHistory } from 'react-router'
import Async from 'react-promise'

var sortBy = require('sort-by');
var firebase = require('firebase');

import Avatar from 'material-ui/Avatar';

class MainTable extends React.Component {
  
  mixins: [ReactFireMixin]

  imageArray:[]

  constructor(props, context) {
    super(props, context);
        

    this.state = {      
      searchColumn: 'all',
      query: { }, // Search query            
      columns: [
      {
          property: 'avatar',
          header: {
            label: ''
          }
        },
        {
          property: 'name',
          header: {
            label: 'Nome'
          },
          cell: {
              format: (value, { rowData }) => (             
                 
                  <span className={ ((rowData.name.length > 30) ? 'smallerText-1' : '') }>{rowData.name}</span>
                 
              )
            }     
        },
        {
          property: 'owner',
          header: {
            label: 'Propretario'
          },
          cell: {
              format: (value, { rowData }) => (             
                 
                  <span className={ ((rowData.owner.length > 30) ? 'smallerText-1' : '') }>{rowData.owner}</span>
                 
              )
            }          
        },
        {
          property: 'creation_date',
          header: {
            label: 'Data Creazione'
          },
          cell: {
              format: (value, { rowData }) => (             
                 
                  <ReactTime value={rowData.creation_date} format="DD/MM/YYYY" />
                 
              )
            }
        },
        {
          property: 'start_date',
          header: {
            label: 'Data inizio'
          },
          cell: {
            format: (value, { rowData }) => (             
               
                <ReactTime value={rowData.start_date} format="DD/MM/YYYY" />               
          )}            
          
        },
        {
          property: 'end_date',
          header: {
            label: 'Data scadenza'
          },
          cell: {
            format: (value, { rowData }) => (             
               
                <ReactTime value={rowData.end_date} format="DD/MM/YYYY" />
               
          )}
          
        },        
        {
          property: 'target_google_group',
          header: {
            label: 'Gruppo Google'
          },
          cell: {
              format: (value, { rowData }) => (             
                 
                  <span className={ ((rowData.target_google_group.length > 30) ? 'smallerText-1' : '') }>{rowData.target_google_group}</span>
                 
              )
            }                 
        },

        {
          property: 'menu',
          header: {
            label: ' '
          },
        }
      ],
      rows: [],
      sortingCreteria:''
    };     

    context.router
  }

  setRowsFromFirebaseEntryToTableEntry(snapshot){
    
    var dataToRow = [];    

      snapshot.forEach(function(childSnapshot) {
        var stubObj = {};

        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();          

        stubObj.id = childSnapshot.key;             

        if(childData.img_storage_link){
          stubObj.avatar = <Async promise={firebase.storage().refFromURL(childData.img_storage_link).getDownloadURL()} then={(val) => <Avatar src={val} />} catch={(val) => <Avatar src={null} />} />;
        }else{
          stubObj.avatar = <Avatar src={null} />;
        }
          

          
          stubObj.name = childData.name;
          stubObj.img_storage_link = childData.img_storage_link;          
          stubObj.owner = childData.owner;
          stubObj.creation_date = childData.creation_date;          
          stubObj.start_date = childData.start_date;
          stubObj.end_date = childData.end_date;
          stubObj.target_google_group = childData.target_google_group;
          stubObj.menu = React.createElement(IconMenuDashboard, {idItem: childSnapshot.key, nameItem: childData.name, templateImgLink: childData.img_storage_link,targetGoogleGroup: childData.target_google_group});
                  
        dataToRow.push(stubObj);          
      });      
  
    //console.log("rows",dataToRow);
    this.setState({rows: dataToRow});    
  }



  componentDidMount(){

    const rootRef = firebase.database().ref('Template');

    rootRef.on('value', snap => {   
          
        this.setRowsFromFirebaseEntryToTableEntry(snap);

    });
       
  }

  componentWillReceiveProps(nextProps) {
  
  
    if(nextProps.queryInput !== this.state.queryInput) {
    //console.log(nextProps.queryInput);
      var obj = { 'all' : nextProps.queryInput };
      this.setState({ query: obj });
    } 
    if(nextProps.sortingCreteria !== this.state.sortingCreteria) {
    //console.log(nextProps.queryInput);
    
      this.setState({sortingCreteria: nextProps.sortingCreteria});

      this.sortByColumn(nextProps.sortingCreteria,false);

    }else if(nextProps.sortingCreteria === this.state.sortingCreteria) {
      
      this.setState({sortingCreteria: nextProps.sortingCreteria});
      
      this.sortByColumn(nextProps.sortingCreteria,true);
    }
  }

  onSelectRow(e,rowObject){
    
    e.preventDefault();

    var strHTMLelement = e.target.toString();
    var cssTextOnElement = e.target.style.cssText;
    var templateId = rowObject.id;

    if((cssTextOnElement.length > 0)
      || (strHTMLelement.indexOf("HTMLButtonElement") > 0)
      || (strHTMLelement.indexOf("HTMLDivElement") > 0)){
      
      return;    
    }else{
      
      this.context.history.push('/template/' + templateId + '/preview');
    }
    
  }

  sortByColumn(col,desc){
 
      if(!desc){
        var zizi = this.state.rows.slice().sort(sortBy(col));
        this.setState({rows:zizi});  
      }else if (desc){
        var zizi = this.state.rows.slice().sort(sortBy(col)).reverse();
        this.setState({rows:zizi});  
      }
    
  }

  render() {

    const { searchColumn, rows, columns, query } = this.state;
    const searchedRows = (search.multipleColumns({ columns, query }))(rows);    
      
    return (
      <div className="dashboard-main-table-scroll-attribute">
   
        <Table.Provider columns={columns} className="table table-striped dashboard-main-table">
	          
          <Table.Header className="table-header-dashboard"/>
 
          <Table.Body className="table-body-dashboard" 
            rows={searchedRows} 
            rowKey="id" 
            onRow={(row,{ end_date }) => {
          
              return {
                className: (row.end_date >= (new Date()).getTime()) ? 'maintable-row table-body-dashboard-row-date' : 'maintable-row',
                onClick: (e) => this.onSelectRow(e,row)
              }
            }}
            />

        </Table.Provider>        
   
      </div>
    );
  }
}

reactMixin(MainTable.prototype, ReactFireMixin)

MainTable.contextTypes = {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
};

module.exports = MainTable;