
var dev = {
  apiKey: "AIzaSyDGLNSUcwbnox1lsrIx9xLhAqx9I-KOstg",
  authDomain: "ovs-visual-check-dev.firebaseapp.com",
  databaseURL: "https://ovs-visual-check-dev.firebaseio.com",
  storageBucket: "ovs-visual-check-dev.appspot.com",
  messagingSenderId: "601971349843"
}

var prod = {
  apiKey: "AIzaSyB0iV1wL2TX0EFJnCyDTdFRXTbE2K4Dz4o",
  authDomain: "ovs-visual-check-prod.firebaseapp.com",
  databaseURL: "https://ovs-visual-check-prod.firebaseio.com",
  projectId: "ovs-visual-check-prod",
  storageBucket: "ovs-visual-check-prod.appspot.com",
  messagingSenderId: "816627135587"
}


module.exports = prod;