var React = require('react');

var firebase = require('firebase');
var Header = require('./Header.js');
var NotificationTable = require('./NotificationTable.js');
var validator = require("email-validator");
var ReactToastr = require("react-toastr");

import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';


import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import Q from 'q';


var {ToastContainer} = ReactToastr;
var ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);


class NotificationsDashboard extends React.Component {

	constructor(props){
		super(props);   

		this.state = {
      		notificationTitle: '',
      		notificationMessage: '',
      		notificationMail: ''
	    }; 
	    
	}
	mailUser: {}

	componentWillMount(){		 		
  		
		this.mailUser = firebase.auth().currentUser.email;		    	
    	
  	}

	componentDidMount(){
		
  	}
  	
  	goDashboard(){
  		this.context.history.push('/dashboard');
  	}

  	addAlert(msgsIn){	
  	var self = this;	
  	
		(function myLoop (i) {          
		   setTimeout(function () {   
		      self.refs.container.error(
			      msgsIn[i-1],
			      "Error", {
			      timeOut: 30000,
			      extendedTimeOut: 10000
			    });	

		      if (--i) myLoop(i);
		   }, 500)
		})(msgsIn.length); 	
  			    
	}

	addSuccessMsg(msgsIn){		

		this.refs.container.success(
	      msgsIn,
	      "Success", {
	      timeOut: 30000,
	      extendedTimeOut: 10000
	    });	
  			    
	}

  	hadndleTitleChange(valIn){
  		
  		this.setState({notificationTitle: valIn});

  	}

  	hadndleMessageChange(valIn){

  		this.setState({notificationMessage: valIn});

  	}

  	hadndleMailChange(valIn){
  		
  		this.setState({notificationMail: valIn});

  	}

  	validateNotification(notificationObj){

  		var deferredValidation = Q.defer();

		var passValidation = true;
		var errMsgsArray = [];

		if(!notificationObj.title){
			passValidation = false;
			errMsgsArray.push("Insert notification title!");
		}
		if(!notificationObj.message){
			passValidation = false;
			errMsgsArray.push("Insert notification message!");
		}
		if(notificationObj.mail){
  			if(!validator.validate(notificationObj.mail)){
	  			passValidation = false;
	  			errMsgsArray.push('Insert valid email address for google group!');	
	  		}
  		}else{
  			passValidation = false;
	  		errMsgsArray.push('Insert Target Google Group!');	
  		}

  		if(passValidation){
	  		deferredValidation.resolve();
	  	}else{
	  		deferredValidation.reject(errMsgsArray);
	  	}

	  	return deferredValidation.promise;

  	}

  	createNotification(){
  		
  		var self = this;

  		var toSendObj = {
  			title: this.state.notificationTitle,
  			message: this.state.notificationMessage,
  			mail: this.state.notificationMail,
  			timestamp: (new Date()).getTime(),
  			owner: this.mailUser,
  			type: 'push_notification'
  		}

  		this.setState({ notificationTitle: '' });
  		this.setState({ notificationMessage: '' });
  		this.setState({ notificationMail: '' });

  		this.validateNotification(toSendObj).then(function(){
  			
  			var nestedSelf_0 = self;
  			
  			self.createNotificationInstanceOnFirebase(toSendObj).then(function(message){
  				
  				nestedSelf_0.addSuccessMsg(message);  				

  			}).catch(function(err){

  				nestedSelf_0.addAlert(err);

  			});
			
  		}).catch(function(errMsgsArray){
  			
  			self.addAlert(errMsgsArray);

  		});
  		
  	}

  	createNotificationInstanceOnFirebase(objectToInsert){
  		
  		var deferredCreation = Q.defer();

  		const notificationRef = firebase.database().ref('notification');

  		notificationRef.push(objectToInsert).then(function(res){
  			console.log(res);
  			deferredCreation.resolve("Notification Created!");
  		}).catch(function(err){
  			console.log(err);
  			deferredCreation.reject("Error");
  		})

  		return deferredCreation.promise;
  	}

  	render(){  	  		  		

	    return (    	    	
	    		<div>	    		
	    			<Header /> 
	    			
	    			<ToastContainer ref="container"
                        toastMessageFactory={ToastMessageFactory}
                        className="toast-top-right" />

	    			<div className="template-container">
	    			<div className="row">
		    				<div className="col-md-3" />
		    				
		    				<div className="col-md-6 notification-container">
		    					<Card>  	    	
	    							<CardText>
		    							<h3>Insert Notification</h3>

		    							<TextField 		   
								    		hintText="Titolo" 	
								    		id="notificationTitle"	
								    		value={this.state.notificationTitle} 
								    		fullWidth={true} 
								    		onChange={(newVal) => {
								    					
		    										this.hadndleTitleChange(newVal.target.value);
								    				
								    			}
								    		}
								    	/>

								    	<TextField 		   
								    		hintText="Message" 	
								    		id="notificationMessage"	
								    		value={this.state.notificationMessage}  
								    		fullWidth={true} 
								    		multiLine={true} 
								    		onChange={(newVal) => {

								    				this.hadndleMessageChange(newVal.target.value);
								    				
								    			}
								    		}
								    	/>

								    	<TextField 		   
								    		hintText="Target Google Group" 	
								    		id="notificationTargetGoogleGroup"	
								    		value={this.state.notificationMail}  
								    		fullWidth={true} 
								    		onChange={(newVal) => {

								    				this.hadndleMailChange(newVal.target.value);
								    				
								    			}
								    		}
								    	/>

		    							<br />
				    					<br />

				    					<div className="text-center">
					    					<FlatButton label={"Insert"}
					    						onClick={this.createNotification.bind(this)} 		    				
					    						backgroundColor="#ddd" />		    					
				    					</div>
				    					<br />


		    						</CardText>
		    					</Card>
		    		
		    				</div>
		    				
		    				<div className="col-md-3" />
		    			</div>	
		    			<div className="row">
		    				<div className="col-md-3" />
		    				
		    				<div className="col-md-6 notification-container">
		    					<Card>  	    	
	    							<CardText>
		    							<div className="scroll-div-for-table">	
		    								<NotificationTable />
		    							</div>
		    						</CardText>
		    					</Card>		    					
		    				</div>
		    				
		    				<div className="col-md-3" />
		    			</div>		    			
	    			</div>
	    		</div>
	    	);
  	} 
}

NotificationsDashboard.contextTypes = {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
  };

module.exports = NotificationsDashboard;
