'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _mergePropPair = require('./merge-prop-pair');

var _mergePropPair2 = _interopRequireDefault(_mergePropPair);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function evaluateTransforms() {
  var transforms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var value = arguments[1];
  var extraParameters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  return transforms.map(function (transform) {
    return transform(value, extraParameters);
  }).filter(function (p) {
    return p;
  }).reduce(_mergePropPair2.default, {}) || {};
}

exports.default = evaluateTransforms;