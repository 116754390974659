var React = require('react');

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';

import Async from 'react-promise';
var firebase = require('firebase');

const answerTypes = [];


class QuestionTypePreview extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			questionTypeValue: 0,
			values: []
		}
	
	}

	componentDidMount(){
		this.setState({questionTypeValue: this.props.questionTypeValue});
    	this.setState({values: this.props.valuesIn});
  	}

  
  	
	
  	render(){
  		const questionTypeValueIn = this.state.questionTypeValue;
		
		let childComponentNode = null;    	

		if(questionTypeValueIn == 0){
			//Lista verticale 
			childComponentNode = <ul className="list-style-singleChoice">{this.state.values.map ((questionData,index) => 
		    			<li id={index} key={index}>{questionData}</li>)}</ul>;
			
		}else if(questionTypeValueIn == 1){	
			//solo testo		
			childComponentNode = <div><p>{this.state.values[0]}</p></div>
			
		}else if(questionTypeValueIn == 2){			
			//Lista orizzontale
			childComponentNode = <ul className="list-style-numbers">{this.state.values.map ((questionData,index) => 
		    			<li id={index} key={index}><img src="../../assets/radio_button.png"/>{questionData}</li>)}</ul>;
			
		}else if(questionTypeValueIn == 3){		
			//Lista verticale 
			childComponentNode = <ul className="list-style-checkList">{this.state.values.map ((questionData,index) => 
		    			<li id={index} key={index}>{questionData}</li>)}</ul>;

		}else if(questionTypeValueIn == 4){			
			//2 valori
			childComponentNode = <div className="numImageContainer"><div className="min-images"><span>Minimo {this.state.values[0]} immagini</span></div>
								 <div className="max-images"><span>Massimo {this.state.values[1]} immagini</span></div></div>;
								 
		}else if(questionTypeValueIn == 5){			
			//2 valori
			childComponentNode = 
				<div className="drop-div-center-image">																								
					<Async promise={firebase.storage().refFromURL(this.state.values[0]).getDownloadURL()} then={(val) => <img src={val} width="400" />} catch={(val) => <img src={null} />} />					
				</div>;
		}else if(questionTypeValueIn == 6){			
			//2 valori
			childComponentNode = <div><p>{this.state.values[0]}</p></div>;
									
		}
	    return (  
	    	<div>
	    	
		    			{childComponentNode}
		    			
        	</div>
	    	);
  	} 
}

module.exports = QuestionTypePreview;
