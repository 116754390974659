'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SearchOptions = require('./SearchOptions');

var _SearchOptions2 = _interopRequireDefault(_SearchOptions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Search = function Search(_ref) {
  var query = _ref.query;
  var _ref$column = _ref.column;
  var column = _ref$column === undefined ? 'all' : _ref$column;
  var columns = _ref.columns;
  var i18n = _ref.i18n;
  var onChange = _ref.onChange;
  var onColumnChange = _ref.onColumnChange;

  var props = _objectWithoutProperties(_ref, ['query', 'column', 'columns', 'i18n', 'onChange', 'onColumnChange']);

  var onOptionsChange = function onOptionsChange(_ref2) {
    var value = _ref2.target.value;

    onChange(_defineProperty({}, value, query[value]));
    onColumnChange(value);
  };
  var onQueryChange = function onQueryChange(_ref3) {
    var value = _ref3.target.value;
    return onChange(_defineProperty({}, column, value));
  };

  return _react2.default.createElement(
    'div',
    props,
    _react2.default.createElement(_SearchOptions2.default, {
      value: column,
      onChange: onOptionsChange,
      columns: columns,
      i18n: i18n
    }),
    columns.length ? _react2.default.createElement('input', { type: 'input', onChange: onQueryChange, value: query[column] || '' }) : null
  );
};
process.env.NODE_ENV !== "production" ? Search.propTypes = {
  column: _react2.default.PropTypes.string,
  columns: _react2.default.PropTypes.array,
  query: _react2.default.PropTypes.object,
  i18n: _react2.default.PropTypes.shape({
    all: _react2.default.PropTypes.string
  }),
  onChange: _react2.default.PropTypes.func,
  onColumnChange: _react2.default.PropTypes.func
} : void 0;
Search.defaultProps = {
  columns: [],
  query: {},
  i18n: {
    all: 'All'
  },
  onChange: function onChange() {},
  onColumnChange: function onColumnChange() {}
};

exports.default = Search;