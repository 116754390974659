'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _countRowSpan = require('./count-row-span');

var _countRowSpan2 = _interopRequireDefault(_countRowSpan);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function resolveHeaderRows() {
  var columns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var resolvedChildren = [];

  var ret = columns.map(function (column) {
    var children = column.children;

    var col = _objectWithoutProperties(column, ['children']);

    if (children && children.length) {
      resolvedChildren = resolvedChildren.concat(resolveHeaderRows(children)[0]);

      return _extends({}, col, {
        props: _extends({}, col.props, {
          colSpan: children.length
        })
      });
    }

    return _extends({}, col, {
      props: _extends({}, col.props, {
        rowSpan: (0, _countRowSpan2.default)(columns)
      })
    });
  });

  if (resolvedChildren.length) {
    return [ret].concat([resolvedChildren]);
  }

  return [ret];
}

exports.default = resolveHeaderRows;