var React = require('react');
var ReactRouter = require('react-router');
var Router = ReactRouter.Router;
var Link = ReactRouter.Link;
import * as firebase from 'firebase';

import ovsBaseTheme from 'material-ui/styles/baseThemes/ovsBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import injectTapEventPlugin from 'react-tap-event-plugin';
import { browserHistory } from 'react-router'


//Necessario per onTouchTap
injectTapEventPlugin();

var Main = React.createClass({
    contextTypes: {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
    },
    getInitialState: function() {
        return {
            loggedIn: (null !== firebase.auth().currentUser)
        }
    },    
    componentWillMount: function() {
        var self = this;

        firebase.auth().onAuthStateChanged(firebaseUser => {

            this.setState({
                loggedIn: (null !== firebaseUser)
            })

            if (firebaseUser) {
                console.log("Logged IN");
            } else {
                self.context.history.push('/login');
                console.log('Not logged in');                
            }
            
        });
    },
    render: function() {
       
        return (
            <span>
                <MuiThemeProvider muiTheme={getMuiTheme(ovsBaseTheme)}>              
                    <div className="container-fluid">
                        <div className="row">
                            {this.props.children}
                        </div>
                    </div>
                </MuiThemeProvider>  
            </span>
        )
    }
});

module.exports = Main;
