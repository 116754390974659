module.exports={
  "_args": [
    [
      {
        "raw": "tough-cookie@~2.3.3",
        "scope": null,
        "escapedName": "tough-cookie",
        "name": "tough-cookie",
        "rawSpec": "~2.3.3",
        "spec": ">=2.3.3 <2.4.0",
        "type": "range"
      },
      "/Users/andreacirri/work/workspace/ovs/visual-dynamic-check-web-console/node_modules/request"
    ]
  ],
  "_from": "tough-cookie@>=2.3.3 <2.4.0",
  "_id": "tough-cookie@2.3.3",
  "_inCache": true,
  "_location": "/tough-cookie",
  "_nodeVersion": "7.9.0",
  "_npmOperationalInternal": {
    "host": "s3://npm-registry-packages",
    "tmp": "tmp/tough-cookie-2.3.3.tgz_1506027901957_0.13104762020520866"
  },
  "_npmUser": {
    "name": "jstash",
    "email": "jstash@gmail.com"
  },
  "_npmVersion": "4.2.0",
  "_phantomChildren": {},
  "_requested": {
    "raw": "tough-cookie@~2.3.3",
    "scope": null,
    "escapedName": "tough-cookie",
    "name": "tough-cookie",
    "rawSpec": "~2.3.3",
    "spec": ">=2.3.3 <2.4.0",
    "type": "range"
  },
  "_requiredBy": [
    "/request",
    "/request-promise"
  ],
  "_resolved": "https://registry.npmjs.org/tough-cookie/-/tough-cookie-2.3.3.tgz",
  "_shasum": "0b618a5565b6dea90bf3425d04d55edc475a7561",
  "_shrinkwrap": null,
  "_spec": "tough-cookie@~2.3.3",
  "_where": "/Users/andreacirri/work/workspace/ovs/visual-dynamic-check-web-console/node_modules/request",
  "author": {
    "name": "Jeremy Stashewsky",
    "email": "jstashewsky@salesforce.com"
  },
  "bugs": {
    "url": "https://github.com/salesforce/tough-cookie/issues"
  },
  "contributors": [
    {
      "name": "Alexander Savin"
    },
    {
      "name": "Ian Livingstone"
    },
    {
      "name": "Ivan Nikulin"
    },
    {
      "name": "Lalit Kapoor"
    },
    {
      "name": "Sam Thompson"
    },
    {
      "name": "Sebastian Mayr"
    }
  ],
  "dependencies": {
    "punycode": "^1.4.1"
  },
  "description": "RFC6265 Cookies and Cookie Jar for node.js",
  "devDependencies": {
    "async": "^1.4.2",
    "string.prototype.repeat": "^0.2.0",
    "vows": "^0.8.1"
  },
  "directories": {},
  "dist": {
    "shasum": "0b618a5565b6dea90bf3425d04d55edc475a7561",
    "tarball": "https://registry.npmjs.org/tough-cookie/-/tough-cookie-2.3.3.tgz"
  },
  "engines": {
    "node": ">=0.8"
  },
  "files": [
    "lib"
  ],
  "gitHead": "12d426678f77bd34dd1234b7acbf47b299f50439",
  "homepage": "https://github.com/salesforce/tough-cookie",
  "keywords": [
    "HTTP",
    "cookie",
    "cookies",
    "set-cookie",
    "cookiejar",
    "jar",
    "RFC6265",
    "RFC2965"
  ],
  "license": "BSD-3-Clause",
  "main": "./lib/cookie",
  "maintainers": [
    {
      "name": "awaterma",
      "email": "awaterma@awaterma.net"
    },
    {
      "name": "jstash",
      "email": "jstash@gmail.com"
    },
    {
      "name": "nexxy",
      "email": "emily@contactvibe.com"
    }
  ],
  "name": "tough-cookie",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git://github.com/salesforce/tough-cookie.git"
  },
  "scripts": {
    "suffixup": "curl -o public_suffix_list.dat https://publicsuffix.org/list/public_suffix_list.dat && ./generate-pubsuffix.js",
    "test": "vows test/*_test.js"
  },
  "version": "2.3.3"
}
