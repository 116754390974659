var React = require('react');

var Header = require('./Header.js');
import FlatButton from 'material-ui/FlatButton';


class TemplateContainer extends React.Component {

	constructor(props){
		super(props);    
	    
	}

	componentDidMount(){
		
  	}
  	
  	goDashboard(){
  		this.context.history.push('/dashboard');
  	}

  	render(){  	
  		
	    return (    	    	
	    		<div>
	    			<Header /> 
	    			<div className="template-container">
		    			<div className="row">
		    				<div className="col-md-3" />
		    				
		    				<div className="col-md-6 privacy-container">
		    					<div className="text-center">
			    					<h1>Privacy</h1>
			    					<div className="text-left">
				    					<p>
				    					Il presente documento, "Informativa Privacy", descrive le modalità del trattamento dei dati raccolti tramite l’applicazione OVS Visual Check (di seguito, per brevità, "App").
										La presente Informativa Privacy è resa solo ed esclusivamente per l’applicazione "App" e non anche per eventuali siti web attraverso i quali ad esempio l’utente dovesse accedere a/o utilizzare l’applicazione "App". L’applicazione App, creata e sviluppata da OVS S.p.A. (di seguito, per brevità, “OVS”), è di proprietà di OVS e da questa interamente gestita.
				    					</p>
				    					<p>
				    					<h3>Titolare del Trattamento</h3> 
				    					Titolare del trattamento dei dati personali, ai sensi dell’art. 28 del Codice Privacy è OVS S.p.A. con sede legale in Venezia Mestre, Via Terraglio, n. 17, 30174 – tel. +39 0412397500
				    					</p>
				    					<p>
				    					Installando questa applicazione, l’utente acconsente alla raccolta e al trattamento dei dati di seguito indicati.
				    					</p>
				    					<p>
				    					<h3>Raccolta dei dati personali e trasmissione dei dati</h3>
				    						<p>
				    						I servizi forniti dalla App, nonché le caratteristiche e le funzioni della stessa richiedono l’utilizzo di un account aziendale. 
				    						</p>
				    						<p>
				    						Segnaliamo tuttavia che i sistemi informatici e le procedure software preposte al funzionamento della App acquisiscono, nel corso del loro normale esercizio, alcuni dati comunque riferibili all’utente la cui trasmissione è implicita nell'uso dei protocolli di comunicazione internet, degli smartphone e dei dispositivi utilizzati. In questa categoria di dati rientrano, a titolo esemplificativo e non esaustivo,  l’identità del telefono, e-mail.
				    						</p>
				    					</p>
				    					<p>
				    					<h3>Tipologia di dati raccolti con la APP e finalità del trattamento</h3>
					    					<p>
					    					OVS nella sua qualità di Titolare del Trattamento dei dati degli utenti, raccoglie questi ultimi per le seguenti finalità:
					    					</p>
						    					<ul>
						    						<li>Valutare i Negozi OVS di cui si è Visual di Piazza, di Zona o Retail;</li>
						    						<li>Ricercare i negozi della propria Area visualizzando il nome del negozio e del Direttore;</li>
						    						<li>Consultare le proprie valutazioni inviate;</li>
						    						<li>Utilizzare la fotocamera per scattare foto presso il negozio; </li>
						    						<li>La possibilità per l’utente di ricevere delle notifiche di invio eseguito con successo sullo smartphone o di nuovi template disponibili;</li>
						    						<li>Mantenere le valutazioni nella memoria del dispositivo finché si è loggati.</li>		    						
						    					</ul>
						    				<p>
						    					Per tali finalità, OVS non è tenuta a richiedere uno specifico consenso, che si intende acquisito a seguito dell’installazione e attivazione delle funzioni della App da parte dell’utente.
						    				</p>
				    					</p>
				    					<p>
				    					<h3>Comunicazione dei dati</h3>
				    						I dati raccolti saranno comunicati e trattati esclusiva mente da personale OVS incaricato al trattamento dal Titolare e non saranno comunicati a terzi. 
				    					</p>
				    						<p>
				    					<h3>Diritti degli Utenti</h3>
				    						L'esercizio dei diritti di cui all'art. 7 del D. Lgs 196/03 potrà effettuarsi attraverso specifica comunicazione da inviarsi alla sede della società OVS S.p.A., Venezia Mestre, Via Terraglio, n. 17, 30174 – tel. +39 0412397500.
				    					</p>
										<p>
				    					<h3>Revoca del consenso</h3>
				    						In qualsiasi momento l’utente potrà disinstallare dal proprio dispositivo la App scaricata, revocando così il consenso al trattamento dei dati.
				    					</p>
				    					<p>
				    					<h3>Modalità del trattamento e misure di sicurezza e riservatezza</h3>
				    						I dati sono trattati con strumenti automatizzati e non, per il solo tempo strettamente necessario a conseguire le finalità per cui sono stati raccolti. 
											Per garantire la sicurezza e la riservatezza dei dati, OVS utilizza reti di dati protetti, tra l'altro, firewall standard del settore e una protezione tramite password ed ha adottato le misure tecniche ed organizzative di cui all’Allegato B al Codice Privacy - "Disciplinare tecnico in materia di misure minime di sicurezza". 
				    					</p>
				    					<p>
				    					<h3>Cookie</h3>
				    						Segnaliamo che l’App non utilizza in alcun modo cookie, nè cookie di profilazione, vale a dire cookie volti a creare profili relativi all’utente, generalmente utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente nell’ambito della navigazione in rete. 
				    					</p>
				    					<p>
				    					<h3>Siti Web di terzi</h3>
				    						Qualora OVS dovesse fornire attraverso l’utilizzo della App link a siti esterni, si segnala sin d’ora che OVS non può esercitare alcun controllo sul contenuto di tali link. 
											Si consiglia pertanto di leggere attentamente le relative politiche sulla privacy ed i termini d'uso di tali siti, prima di fornire i propri dati personali.
										</p>
										<p>
				    					<h3>Aggiornamenti dell’Informativa</h3>
				    						Si informa che la presente Informativa, resa ai sensi dell’art. 13 del Codice Privacy potrà essere soggetta ad aggiornamenti periodici di cui verrà data evidenza da OVS con modalità idonee a renderne edotti gli interessati. 
				    					</p>
				    					<p>
				    					<h3>Data di ultima modifica</h3>
				    						La presente informativa è stata aggiornata il 20/03/2017. 
				    					</p>
				    					<p>		    					
				    						Installando questa applicazione, l’utente acconsente alla raccolta ed al trattamento dei dati per le finalità e con le modalità specificate nella presente informativa.
				    					</p>
				    					<p>
				    						<h3>Nel caso l’utente non volesse fornire tale consenso, non potrà accedere alle funzioni della App.</h3>
				    					</p>
				    				</div>
			    					<FlatButton label={"HOME"}
			    						onClick={this.goDashboard.bind(this)} 		    				
			    						backgroundColor="#fff" />
			    					
			    					<br />
			    					<br />
			    				</div>
		    				</div>
		    				
		    				<div className="col-md-3" />
		    			</div>		    			
	    			</div>
	    		</div>
	    	);
  	} 
}

TemplateContainer.contextTypes = {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
  };

module.exports = TemplateContainer;
