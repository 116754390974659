import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';


/**
 * A modal dialog can only be closed by selecting one of the actions.
 */
 var firebase = require('firebase');

  const customContentStyle = {
    height: '400px'  
  };

class RenameDialog extends React.Component {

  constructor(props){
      super(props);    
      
      this.state = { 
        open: true,        
      }      
      
  }

  handleAction() {    
    let newName = this._newName.value;
            
    firebase.database().ref('Template/' + this.props.idRowElement).update({name: newName});
    
    this.setState({open: false});
  }

  handleClose() {
    console.log("closing",this.props.idRowElement);    
    this.setState({open: false});    
  }

 componentWillReceiveProps(nextProps) {
  
  if (nextProps.stateOpen !== this.state.open) {
      this.setState({ open: true });
    } 
  }
  
  render() {
     
    const actions = [
     
      <FlatButton
        label="OK"       
        onTouchTap={this.handleAction.bind(this)}
      />,
       <FlatButton
        label="ANNULLA"
        onTouchTap={this.handleClose.bind(this)}        
      />
    ];

    return (

      <div>
        <MuiThemeProvider>  
          <Dialog
            title="Rinomina"
            actions={actions}         
            modal={true}
            open={this.state.open} 
            contentStyle={customContentStyle}
            >
            Immetti il nuovo nome per il Template:
            <br /><br />
              
              <input placeholder={this.props.nameRowElement} ref={(input) => this._newName = input}/>
              
          </Dialog>
        </MuiThemeProvider>  
      </div>
    );
  }
}

module.exports = RenameDialog;