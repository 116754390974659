var React = require('react');

import { CirclePicker } from 'react-color';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import Popover, {PopoverAnimationVertical} from 'material-ui/Popover';
import Avatar from 'material-ui/Avatar';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import FlatButton from 'material-ui/FlatButton';
import moment from 'moment';


const FileUpload = require('react-fileupload');


const colors = [
"#f44336", 
"#e91e63", 
"#9c27b0", 
"#673ab7", 
"#3f51b5", 
"#2196f3", 
"#03a9f4", 
"#00bcd4", 
"#009688", 
"#4caf50", 
"#8bc34a", 
"#cddc39", 
"#ffeb3b",
"#607d8b"
];

class TemplateInfo extends React.Component {

	constructor(props){
		super(props);

		this.state = {
      		openPopover: false,
      		anchorEl: null,
      		selectedColor: '',
      		base64TemplateImage:null
	    };
 
	}

	componentDidMount(){
    	
  	}
	
  	handlePopoverTouchTap(event){
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget,
    });

  	}

	handlePopoverRequestClose(){
	    this.setState({
	      openPopover: false,
	    });
	}


	createOnChangeTemplateObject(){		
		var newObject = this.props.templateInfo;
		return newObject;
	}

	handleColorSelect(color, event) {
				
		this.setState({selectedColor: color.hex});
		this.props.templateInfo.type_color = color.hex;

  	}

  	handleImageB64Preview(fileIn){

  		let self = this;

  		let reader  = new FileReader();

  		reader.addEventListener("load", function () {		    
		    
		    self.setState({base64TemplateImage: reader.result});

		  }, false);

		  if (fileIn) {
		    reader.readAsDataURL(fileIn);
		  }
  		

  	}

  	render(){

  		var newTemplateInfo = this.createOnChangeTemplateObject();

  		var options = {
  			baseUrl: '',  		
			chooseFile : function(files){
        			//console.log('you choose',typeof files == 'string' ? files : files[0].name);   
        			this.handleImageB64Preview(files[0]);     
        			this.props.imageFileAddHandler(files[0]);
    		}.bind(this)    		
    	};

	    return (  
	    	<Card>  	    	
	    		<CardText>
	    		<div>
		    		<Avatar src={this.state.base64TemplateImage} />
		    		<div className="uploadButtonStyle">
		    			<FileUpload options={options}>				    
					    	<FlatButton label="IMAGE" ref="chooseBtn" backgroundColor="#ddd" />
						</FileUpload>
					</div>
					<div className="templateNameInput">
			    		<TextField 		   
			    		hintText="Nome Template" 	
			    		id="textFieldTemplateName"	
			    		value={newTemplateInfo.name} 
			    			fullWidth={true} 
			    			onChange={(newVal) => {

			    					newTemplateInfo.name = newVal.target.value;
			    					this.props.changeHandler(newTemplateInfo);
			    				}
			    			}
			    			/>
					</div>
				</div>		    			
		    		<TextField value={newTemplateInfo.description}		    		
		    			hintText="Descrizione" 
		    			id="textFieldTemplateInfo"			
		    			fullWidth={true} 
		    			onChange={(newVal) => {

		    					newTemplateInfo.description = newVal.target.value;
		    					this.props.changeHandler(newTemplateInfo);
		    				}
		    			}
		    			/>
		    		<TextField value={newTemplateInfo.target_google_group} 
		    			fullWidth={true} 
		    			id="textFieldTemplateGoogleGroup"	
		    			hintText="Gruppo Google" 		
		    			onChange={(newVal) => {

		    					newTemplateInfo.target_google_group = newVal.target.value;
		    					this.props.changeHandler(newTemplateInfo);
		    				}
		    			}
		    			/>	
		    		<TextField value={newTemplateInfo.type} 
		    			fullWidth={true} 
		    			id="textFieldTemplateGoogleGroup"	
		    			hintText="Tipologia" 		
		    			onChange={(newVal) => {

		    					newTemplateInfo.type = newVal.target.value;
		    					this.props.changeHandler(newTemplateInfo);
		    				}
		    			}
		    			/>		    			    			

		    		<div className="templateInfo-date-container">

			    		<div className="templateInfo-date-float-left">
			    			
			    			<span>Data inizio</span>

			    			<DatePicker hintText={newTemplateInfo.start_date} 
			    				id="start_date"
			    				textFieldStyle={{ 'margin-top': '-15px' , 'margin-left': '20px' }}
			    				value={new Date(newTemplateInfo.start_date)} 			    			
			    				formatDate={ (date) => {

						                   return moment(date).format("DD/MM/YYYY");
						          } 
						       	} 
			    				onChange={ (nullVlaue,date) => {

						                   console.log(date.getTime());
						                   newTemplateInfo.start_date = date.getTime();
						                   this.props.changeHandler(newTemplateInfo);
						                   
						          } 
						       } 
						    />
			    			
			    			<i className="fa fa-calendar-o" aria-hidden="true"></i>
						</div>
			    		<div className="templateInfo-date-float-right">
			    			<span>Data fine</span>
			    			<DatePicker hintText={newTemplateInfo.end_date} 
			    				id="end_date"
			    				textFieldStyle={{ 'margin-top': '-15px' , 'margin-left': '20px' }} 
			    				value={new Date(newTemplateInfo.end_date)} 			    			
			    				formatDate={ (date) => {

						                   return moment(date).format("DD/MM/YYYY");
						          } 
						       	} 
			    				onChange={ (nullVlaue,date) => {

						                   console.log(date.getTime());
						                   newTemplateInfo.end_date = date.getTime();
						                   this.props.changeHandler(newTemplateInfo);
						                   
						          } 
						       } />
			    			<i className="fa fa-calendar-o" aria-hidden="true"></i>
			    		</div>

		    		</div>		

        <FlatButton
          onTouchTap={this.handlePopoverTouchTap.bind(this)}
          label="COLOR"
          backgroundColor="#ddd"
        />

        <Popover
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
          targetOrigin={{horizontal: 'left', vertical: 'top'}}
          onRequestClose={this.handlePopoverRequestClose.bind(this)}
        >
        	
        	<CirclePicker onChange={ this.handleColorSelect.bind(this) } width="300px" colors={colors}/>

        </Popover>
     
		<TextField value={newTemplateInfo.type_color} 
		    			id="textFieldTemplateGoogleGroup"	
		    			hintText="Colore" 		
		    			onChange={(newVal) => {

		    					newTemplateInfo.type_color = newVal.target.value;
		    					this.props.changeHandler(newTemplateInfo);
		    				}
		    			}
		/>		    		
		             	

        	</CardText>
       	</Card>	
	    	);
  	} 
}

module.exports = TemplateInfo;
