var React = require('react');


import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';
import MultipleChoiceIcon from 'material-ui/svg-icons/image/adjust';

const answerTypes = [];

class CheckList extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			questionListValues: []			
		}
	
	}

  	componentWillMount(){
    	this.setState({questionListValues: this.props.valuesIn});
  	}

  	hanldeAddAnswer(index){
  		
  		var textEmptyQuestion = "opzione ";
  		if(this.state.questionListValues){
  			var id = this.state.questionListValues.length + 1;
  			textEmptyQuestion += id;  			
        var newArray = this.state.questionListValues.concat([textEmptyQuestion]);
        this.setState({questionListValues: newArray}); 
        //Settare lo stato del padre
        this.props.valuesHandler(newArray); 
  		}else{
  			var newArray = [];
        textEmptyQuestion += '1';
        newArray.push(textEmptyQuestion);
        this.setState({questionListValues: newArray}); 
        //Settare lo stato del padre
        this.props.valuesHandler(newArray);
  		}

  	}

  	hanldeRemoveAnswer(index){
  		
  		//gestione array
  		var newArray = this.state.questionListValues;
  		newArray.splice(index, 1);
  		this.setState({questionListValues: newArray});
  		//Settare lo stato del padre
  		this.props.valuesHandler(this.state.questionListValues);	  		
  		
  	}
  	
  	handleTextFieldChange(index,newVal){
  		
  		//gestione array
  		var newArray = this.state.questionListValues;
  		newArray[index] = newVal.target.value;
  		this.setState({questionListValues: newArray});
  		//Settare lo stato del padre
  		this.props.valuesHandler(newArray);	  		
  		
  	}
	
  	render(){
  		
	    return (  
	    	<div>
		   	<ul className="list-style-checkList">
		    	{ this.state.questionListValues.map ( (questionValue, index) => 
		    		<li key={index}>
		    		 <div className="question-choice-inline">
		    		
			    		<div>			    							   
			    		
			    		<TextField hintText={questionValue} id={"CheclistField" + index}
			    			value={questionValue}
			    			onChange={(newVal) => {
				    				
				    				this.handleTextFieldChange(index,newVal);				    				
				    		}} /> 				    	

				    	</div>
			    	<div onClick={this.hanldeRemoveAnswer.bind(this,index)} 
			    		className="question-choice-inline-component clear-question-char"><span>X</span></div>
			    	</div>

			    	</li>
			    )}
	    	</ul>
	    	
	    	  <div>
	    		
	    			<div className="question-addQuestionItem" onClick={this.hanldeAddAnswer.bind(this)}>
            <img src="../../assets/check_box.png" />
            <span>Aggiungi opzione</span>

          </div>
	    		
		    </div>			
        	</div>
	    	);
  	} 
}

module.exports = CheckList;
