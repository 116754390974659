'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _single_column = require('./single_column');

var _single_column2 = _interopRequireDefault(_single_column);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var multipleColumns = function multipleColumns(_ref) {
  var castingStrategy = _ref.castingStrategy;
  var columns = _ref.columns;
  var query = _ref.query;
  var strategy = _ref.strategy;
  var transform = _ref.transform;
  return function (data) {
    return query ? Object.keys(query).reduce(function (filteredData, searchColumn) {
      return (0, _single_column2.default)({
        castingStrategy: castingStrategy,
        columns: columns,
        searchColumn: searchColumn,
        query: query[searchColumn],
        strategy: strategy,
        transform: transform
      })(filteredData);
    }, data) : data;
  };
};

exports.default = multipleColumns;