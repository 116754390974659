'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _resizable_column = require('./resizable_column');

var _resizable_column2 = _interopRequireDefault(_resizable_column);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _resizable_column2.default;