'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _mergeClassNames = require('./merge-class-names');

var _mergeClassNames2 = _interopRequireDefault(_mergeClassNames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function mergePropPair() {
  var a = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var b = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var ret = _extends({}, a, b, {
    style: _extends({}, a.style, b.style),
    className: (0, _mergeClassNames2.default)(a.className, b.className)
  });

  if (a.children || b.children) {
    ret.children = _extends({}, b.children, a.children); // Reverse order
  }

  return ret;
}

exports.default = mergePropPair;