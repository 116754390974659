'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _value = require('./value');

var _value2 = _interopRequireDefault(_value);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var highlightCell = function highlightCell(value) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { rowData: { _highlights: {} } };

  var rowData = _ref.rowData;
  var property = _ref.property;
  return (0, _value2.default)(value, rowData._highlights && rowData._highlights[property]);
};

exports.default = highlightCell;