var React = require('react');
var ReactDOM = require('react-dom');
import { Table, search, Search, sort } from 'reactabular';
import IconMenuDashboard from './IconMenuDashboard';
import NotificationMsgDialog from './NotificationMsgDialog';
import ReactFireMixin from 'reactfire'
import reactMixin from 'react-mixin'
import ReactTime from 'react-time'
import { browserHistory } from 'react-router'
import Async from 'react-promise'


var sortBy = require('sort-by');
var firebase = require('firebase');

import Avatar from 'material-ui/Avatar';

class NotificationTable extends React.Component {
  
  mixins: [ReactFireMixin]

  imageArray:[]

  constructor(props, context) {
    super(props, context);
        

    this.state = {      
      searchColumn: 'all',
      query: { }, // Search query            
      columns: [
        {
          property: 'title',
          header: {
            label: 'Titolo'
          }
        },
        {
          property: 'mail',
          header: {
            label: 'Mail'
          }
        },
        {
          property: 'owner',
          header: {
            label: 'Propretario'
          }
        },
        {
          property: 'timestamp',
          header: {
            label: 'Data Creazione'
          },
          cell: {
              format: (value, { rowData }) => (             
                 
                  <ReactTime value={rowData.timestamp} format="DD/MM/YYYY" />
                 
              )
            }          
        }
      ],
      rows: [],
      sortingCreteria:''
    };     

    context.router
  }

  setRowsFromFirebaseEntryToTableEntry(snapshot){
    
    var dataToRow = [];    

      snapshot.forEach(function(childSnapshot) {
          var stubObj = {};

         var childKey = childSnapshot.key;
         var childData = childSnapshot.val();          

          stubObj.id = childSnapshot.key;             
          
          stubObj.mail = childData.mail;
          stubObj.message = childData.message;          
          stubObj.owner = childData.owner;
          stubObj.timestamp = childData.timestamp;  
          stubObj.title = childData.title;        
          stubObj.type = childData.type;
          
        
        if(stubObj.type === 'push_notification'){
          dataToRow.push(stubObj);          
        }
          
      });    

    //Sort rows by creation data (by state)     
      this.setState({rows: dataToRow.slice().sort(sortBy('timestamp')).reverse()}); 
    
     

  }



  componentDidMount(){

    const rootRef = firebase.database().ref('notification');

    rootRef.on('value', snap => {   
          
        this.setRowsFromFirebaseEntryToTableEntry(snap);

    });
       
  }

  componentWillReceiveProps(nextProps) {
     
  }

  onSelectRow(e,rowObject){
    
    e.preventDefault();
    
    var notificationId = rowObject.id;

    ReactDOM.render(<NotificationMsgDialog notificationObj={rowObject}                   
                  stateOpen={true}/>, document.getElementById('ModalDivUsers'));
            
    
  }

  sortByColumn(col,desc){
 
      if(!desc){
        var zizi = this.state.rows.slice().sort(sortBy(col));
        this.setState({rows:zizi});  
      }else if (desc){
        var zizi = this.state.rows.slice().sort(sortBy(col)).reverse();
        this.setState({rows:zizi});  
      }
    
  }

  render() {

    const { searchColumn, rows, columns, query } = this.state;
    const searchedRows = (search.multipleColumns({ columns, query }))(rows);    
      
    return (
      <div>        

        <Table.Provider columns={columns} className="table table-striped dashboard-main-table">
	          
          <Table.Header className="table-header-dashboard"/>
 
          <Table.Body className="table-body-dashboard" 
            rows={searchedRows} 
            rowKey="id" 
            onRow={(row,{ end_date }) => {
          
              return {
                className: 'maintable-row',
                onClick: (e) => this.onSelectRow(e,row)
              }
            }}
          />

        </Table.Provider>        
   
      </div>
    );
  }
}

reactMixin(NotificationTable.prototype, ReactFireMixin)

NotificationTable.contextTypes = {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
};

module.exports = NotificationTable;