'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

var _has = require('lodash/has');

var _has2 = _interopRequireDefault(_has);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function nested(_ref) {
  var rowData = _ref.rowData;
  var column = _ref.column;
  var property = column.property;


  if (!property) {
    return {};
  }

  if (!(0, _has2.default)(rowData, property)) {
    console.warn( // eslint-disable-line no-console
    'resolve.nested - Failed to find "' + property + '" property from', rowData);

    return {};
  }

  return _extends({}, rowData, _defineProperty({}, property, (0, _get2.default)(rowData, property)));
}

exports.default = nested;