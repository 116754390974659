"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function resolveBodyColumns(columns) {
  var ret = [];

  columns.forEach(function (column) {
    // If a column has children, skip cell specific configuration
    if (column.children && column.children.length) {
      ret = ret.concat(resolveBodyColumns(column.children));
    } else {
      ret.push(column);
    }
  });

  return ret;
}

exports.default = resolveBodyColumns;