"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var SearchColumns = function SearchColumns(_ref) {
  var columns = _ref.columns;
  var query = _ref.query;
  var onChange = _ref.onChange;

  var onQueryChange = function onQueryChange(event) {
    onChange(_extends({}, query, _defineProperty({}, event.target.name, event.target.value)));
  };

  return _react2.default.createElement(
    "tr",
    null,
    columns.map(function (column, i) {
      return _react2.default.createElement(
        "th",
        { key: (column.property || i) + "-column-filter", className: "column-filter" },
        column && column.property ? _react2.default.createElement("input", {
          onChange: onQueryChange,
          className: "column-filter-input",
          name: column.property,
          placeholder: column.filterPlaceholder || '',
          value: query[column.property]
        }) : ''
      );
    })
  );
};
process.env.NODE_ENV !== "production" ? SearchColumns.propTypes = {
  columns: _react2.default.PropTypes.arrayOf(_react2.default.PropTypes.object).isRequired,
  onChange: _react2.default.PropTypes.func.isRequired,
  query: _react2.default.PropTypes.object
} : void 0;

exports.default = SearchColumns;