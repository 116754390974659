var React = require('react');

var Header = require('./Header.js');
var TemplateBox = require('./TemplateBox.js');
var TemplatePreview = require('./TemplatePreview.js');

class TemplateContainer extends React.Component {

	constructor(props){
		super(props);    
	    
	}

	componentDidMount(){
		
  	}

  	render(){

  		var contentNode = <TemplateBox templateId={this.props.params.templateId} mode={this.props.params.mode}/>;

  		if(this.props.params.mode === 'preview'){
  			contentNode = <TemplatePreview templateId={this.props.params.templateId} />;
  		}
  		
	    return (    	    	
	    		<div>
	    			<Header fatherRoute={'3rdLevel'}/> 
	    			<div className="template-container">
		    			<div className="row">
		    				<div className="col-md-2" />
		    				
		    				<div className="col-md-10 template-info-container">
		    					{contentNode}
		    				</div>
		    				
		    			</div>		    			
	    			</div>
	    		</div>
	    	);
  	} 
}

module.exports = TemplateContainer;
