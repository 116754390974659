var React = require('react');

import IconHeaderUserMenu from './IconHeaderUserMenu';
const style = {margin: 5};
const appVersion = (document.querySelectorAll("meta[property='frontend.app.runtime.version']")[0] || {}).content;

import { browserHistory } from 'react-router';

class Header extends React.Component {

  comomentDidMount(){
  	console.log("r ->", this.props.path);
  }

  goToHome(){
  	this.context.history.push('/dashboard');                    
  }

  render(){
  	var baseRoute = './';
  	if(this.props.fatherRoute === '3rdLevel'){
  		baseRoute = './../../../';
  	}

    return (
	 	<div className="row">
	    				<div className="col-md-2 text-left">
	    					 
	    				</div>

	    				<div className="col-md-8">
	    					<div className="col-md-4" />
	    					<div className="col-md-4 text-center">
		    					<div className="header-logo-margin">		    						
			    					<img alt="Ovs Brand" src={baseRoute + 'assets/logo_navbar.png'} onClick={this.goToHome.bind(this)}/>
			    					<div className="header-app-name" onClick={this.goToHome.bind(this)} >
				    					VISUAL DYNAMIC CHECK 
				    					<span>{appVersion}</span>
			    					</div>
		    					</div>
					    	</div>	    					
	    				</div>	
	    				
	    				<div className="col-md-2 avatar-header">	    				
	    					
	    					<IconHeaderUserMenu />
	    					
	    				</div>
	    </div>		
	
    	)
  }

  _handleChangeInput(e){  	
  	//console.log(e.target.value);
  	//this.props.searchFunc(e.target.value);
  }

}

Header.contextTypes = {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
};

module.exports = Header;