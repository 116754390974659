'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Select = require('./Select');

var _Select2 = _interopRequireDefault(_Select);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var byArrowKeys = function byArrowKeys(props) {
  return function (children) {
    return _react2.default.createElement(_Select2.default, props, children);
  };
};

exports.default = byArrowKeys;