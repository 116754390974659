import React, { Component } from 'react';
import * as firebase from 'firebase';
import GoogleLogin from 'react-google-login';

var GenericDialog = require('../secure/GenericDialog.js');

var Spinner = require('react-spinkit');

var rp = require('request-promise');

var prod = true;

var clientId = prod ? '816627135587-mqsv75nicti55j6ithe0q13oon9sp93i.apps.googleusercontent.com' : '601971349843-0s7svcvsvs7urh8c9cp7fr2jbgkdf767.apps.googleusercontent.com';
var baseAuthUrl = prod ? 'https://dynamiccheckauth.gcp.ovs.it:80/auth/' : 'https://dynamiccheckauth.gcp.ovs.it:81/auth/';

var Login = React.createClass({   

    contextTypes: {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
    },

    getInitialState: function(){
        return {
            errorMsg: '',
            showLoader: false
        };
    },

    componentWillMount: function() {
       //se l'utente è loggato, redirect in /dashboard
        var self = this; 

        firebase.auth().onAuthStateChanged(firebaseUser => {

            if (firebaseUser) {
                console.log("Logged in");

                firebaseUser.getIdToken()
                .then((idToken) => {
                    // Parse the ID token.
                    const payload = JSON.parse(self.b64DecodeUnicode(idToken.split('.')[1]));
                    // Confirm the user is an Admin.
                    if (!!payload['admin']) {
                        console.log("User admin");                            
                        
                        var location = self.props.location;
                        
                        if(location.state && location.state.nextPathname){
                            self.context.router.replace(location.state.nextPathname);
                        }else{
                            self.context.router.replace('/dashboard');
                        }
    
                    }else{
                        console.log("User not admin");   
                        firebase.auth().signOut();                        
                        self.setState({userNotAllowed : true});        
                    }
                })
                .catch((error) => {
                console.log(error);
                });  

            } else {
                console.log('Not logged in');                
            }
            
        });

    },

    b64DecodeUnicode: function(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    },

    handleSubmit: function(googleResponse){

        this.setState({showLoader: true});

        let authTokenFromCirri = googleResponse.tokenId;

        let userGoogleEmail = googleResponse.profileObj.email;
        let userGoogleName = googleResponse.profileObj.name;

        console.log(userGoogleEmail);

        var self = this;    
                
        if(authTokenFromCirri){

            rp(baseAuthUrl + authTokenFromCirri).then(function (responceIn) {                                
            //rp('http://localhost:3001/auth/' + authTokenFromCirri).then(function (responceIn) {                                
                    
                firebase.auth().signInWithCustomToken(JSON.parse(responceIn).token).then(function(result) {       
                    
                    self.setState({showLoader: false});                                                                                                    
                    
                }).catch(function (err) {

                    console.error("firebaseAuth",err);
                    self.setState({showLoader: false});                                                                                
                    
                });
            }).catch(function(err){

                // TODO popup errore
                console.error("nodeAuth",err);
                self.setState({showLoader: false, userNotAllowed : true});                                                                                
                
            });
        }
        
    },
    render: function(){
        
        let loaderNode = null;

        if(this.state.showLoader){
            loaderNode = <Spinner spinnerName='chasing-dots' />;
        }

        let clientIdParam = clientId;

        return (
            <div className="center-login">
            <div className="col-md-12">
                <div className="row text-center">
                    <div className="col-md-3" />
                    <div className="col-md-6">
                        <img src={'./assets/login_logo.png'} className="img-responsive"/>
                        <br /><br />
                    </div>
                    <div className="col-md-3" />
                </div>
                
                <div className="row text-center">                   
                        
                        <div className="login-loader-container">
                            {loaderNode}
                        </div>

                        <GoogleLogin 
                                clientId={clientIdParam}
                                buttonText=""
                                className="btn btn-block btn-social btn-google login-button-custom"
                                scope="profile email https://www.googleapis.com/auth/admin.directory.group.readonly"
                                onSuccess={this.handleSubmit}
                                onFailure={this.handleSubmit} >
                                <span className="fa fa-google"></span>
                                 Sign in with Google
                        </GoogleLogin>
                                     

                        <GenericDialog 
                        notification_text="User not authorized." 
                        notification_title="Authentication Error"
                        stateOpen={this.state.userNotAllowed}/>
                </div>

                <div className="row text-center">
                     {this.state.errorMsg}
                </div>
            </div>            
            </div> 
        );
    }
});

module.exports = Login;
