var React = require('react');
var firebase = require('firebase');

var Header = require('./Header.js');
var MainTable = require('./MainTable.js');

import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import IconMenuDashboard from './IconMenuDashboard';
import CreateTemplateButton from './CreateTemplateButton';
import Toggle from 'material-ui/Toggle';

import InitialImport from './initializeTemplates';
import InitialImportQuestions from './initializeQuestions';

class Dashboard extends React.Component {

	constructor(props,context){
		super(props);    
	    this.state = { 
	    	filterText: '',
	    	sortingType: 'creation_date',
	    	userEmail:'',
	    	imageArray:[]
	    };
	    this.handleChange = this.handleChange.bind(this);
	    context.router
	}	
	
	mailUser: {}

	userFullName:{}		

	componentDidMount(){		

		let userData = firebase.auth().currentUser;
		this.mailUser = userData.email;
		this.userFullName = userData.displayName;
  	}

	handleChange(event) {
    	this.setState({filterText: event.target.value});
  	}

  	handleSearchFunc(inputVal){
  		this.setState({filterText: inputVal});
  	}

  	handleCreatedByMeFilter(event,toggle){
  		if(toggle){
  			
  			this.setState({filterText: this.userFullName});		
  		}else{
  			
  			this.setState({filterText: ''});		
  		}  		
  			
  	}

  	handleChangeSorting(val){
  		this.setState({sortingType: val})  		  		
  	}

  	render(){
	    return (    	    	
	    		<div className="text-center">

	    		<Header /> 

	    		<div id="search-toolbar" className="row search-bar-dashboard text-center">
	    			<div className="lg-small-sorting-list">
	    				<span>Ordina Per:</span>

	    				<span className={"search-bar-dashboard-sorting-element " + 
	    					(this.state.sortingType === 'creation_date' ? 'search-bar-dashboard-sorting-element-selected' : '')}
	    					onClick={() => this.handleChangeSorting('creation_date')} >Data Creazione</span>

	    				<span className={"search-bar-dashboard-sorting-element " + 
	    					(this.state.sortingType === 'start_date' ? 'search-bar-dashboard-sorting-element-selected' : '')}
	    					onClick={() => this.handleChangeSorting('start_date')} >Data inizio</span>

	    				<span className={"search-bar-dashboard-sorting-element " + 
	    					(this.state.sortingType === 'end_date' ? 'search-bar-dashboard-sorting-element-selected' : '')}
	    					onClick={() => this.handleChangeSorting('end_date')}>Data fine</span>	  	


	    				<span className={"search-bar-dashboard-sorting-element " + 
	    					(this.state.sortingType === 'owner' ? 'search-bar-dashboard-sorting-element-selected' : '')}
	    					onClick={() => this.handleChangeSorting('owner')} >Proprietario</span>	    			

	    				<span className={"search-bar-dashboard-sorting-element " + 
	    					(this.state.sortingType === 'name' ? 'search-bar-dashboard-sorting-element-selected' : '')}
	    					onClick={() => this.handleChangeSorting('name')} >Nome</span>

	    				<span className={"search-bar-dashboard-sorting-element " + 
	    					(this.state.sortingType === 'target_google_group' ? 'search-bar-dashboard-sorting-element-selected' : '')}
	    					onClick={() => this.handleChangeSorting('target_google_group')} >Gruppo Google</span>	    						    				
	    				
	    				<span className="toggleDashboard">
	    					<Toggle onToggle={this.handleCreatedByMeFilter.bind(this)} />
	    				</span>
	    				<span className="search-bar-dashboard-sorting-element search-bar-toggle-label">
	    				Creati da me</span>
	    							
	    			</div>

	    			<div className="sm-small-sorting-list">

	    				<ul>
		    				<li><span>Ordina Per:</span></li>

		    				<li><span className={"search-bar-dashboard-sorting-element " + 
		    					(this.state.sortingType === 'creation_date' ? 'search-bar-dashboard-sorting-element-selected' : '')}
		    					onClick={() => this.handleChangeSorting('creation_date')} >Data Creazione</span></li>

		    				<li><span className={"search-bar-dashboard-sorting-element " + 
		    					(this.state.sortingType === 'start_date' ? 'search-bar-dashboard-sorting-element-selected' : '')}
		    					onClick={() => this.handleChangeSorting('start_date')} >Data inizio</span></li>

		    				<li><span className={"search-bar-dashboard-sorting-element " + 
		    					(this.state.sortingType === 'end_date' ? 'search-bar-dashboard-sorting-element-selected' : '')}
		    					onClick={() => this.handleChangeSorting('end_date')}>Data fine</span></li>	


		    				<li><span className={"search-bar-dashboard-sorting-element " + 
		    					(this.state.sortingType === 'owner' ? 'search-bar-dashboard-sorting-element-selected' : '')}
		    					onClick={() => this.handleChangeSorting('owner')} >Proprietario</span></li>

		    				<li><span className={"search-bar-dashboard-sorting-element " + 
		    					(this.state.sortingType === 'name' ? 'search-bar-dashboard-sorting-element-selected' : '')}
		    					onClick={() => this.handleChangeSorting('name')} >Nome</span></li>

		    				<li><span className={"search-bar-dashboard-sorting-element " + 
		    					(this.state.sortingType === 'target_google_group' ? 'search-bar-dashboard-sorting-element-selected' : '')}
		    					onClick={() => this.handleChangeSorting('target_google_group')} >Gruppo Google</span></li>
		    				
		    						    			
		    				<li><span className="toggleDashboard">
		    					Creati da me:<Toggle onToggle={this.handleCreatedByMeFilter.bind(this)} />
		    				</span></li>
		    			</ul>				
	    			</div>
	    		<div>
	        			 	
	        			 </div>
	    				 
	    		</div> 	
	    			<div className="row text-center">
	    				<div className="col-md-2" />
	    				
	    				<div className="col-md-8">
	    					<br />
	    					<div className="inner-addon left-addon">
									<i className="glyphicon glyphicon-search"></i>    
									<input type="text" className="form-control" placeholder="Search" 
									onChange={this.handleChange.bind(this)} />
							</div>
	    					<br />
	    					
	    					<MainTable queryInput={this.state.filterText} sortingCreteria={this.state.sortingType} />

	    				</div>	
	    				
	    				<div className="col-md-2" />
	    			</div>	
	    			<div className="row">
	    				<div className="pull-right margin-right-button-add-dashboard">
	    					
      						<CreateTemplateButton />	
    						
	    				</div>
	    			</div>
	    		</div>  		
	    	);
  	} 
}

Dashboard.contextTypes = {
        router: React.PropTypes.object.isRequired
};

module.exports = Dashboard;
