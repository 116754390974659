var React = require('react');


import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';
import MultipleChoiceIcon from 'material-ui/svg-icons/image/adjust';
import FlatButton from 'material-ui/FlatButton';

var blobUtil = require('blob-util');
var Dropzone = require('react-dropzone');

const answerTypes = [];
const FileUpload = require('react-fileupload');

class ShowImageQuestion extends React.Component {

  	constructor(props){
  		super(props);
  		this.state = {
  			questionListValues: [],
        imageB64Data: '',
        files: []	
  		}
  	
  	}

  	componentWillMount(){
    	this.setState({questionListValues: this.props.valuesIn});
  	}

  	
  	
  	handleTextFieldChange(index,newVal){
  		
  		//gestione array
  		var newArray = this.state.questionListValues;
  		newArray[index] = newVal.target.value;
  		this.setState({questionListValues: newArray});
  		//Settare lo stato del padre
  		this.props.valuesHandler(newArray);	  		
  		
  	}

    onDrop(files) {

      this.setState({
        files: files
      });

      this.props.valuesHandler(files); 

      console.log('Received files: ', files);
    }

    onOpenClick() {
      this.refs.dropzone.open();
    }
	
  	render(){  		

	    return (  
	    	<div>
		   		
          {this.state.files.length > 0 ? <div className="drop-div-center-image">

              <img src={this.state.files[0].preview} width="400"/>
          
          </div> : null}  
          <br />
  	    	<div className="drop-div-center">
  	    		
            <Dropzone onDrop={this.onDrop.bind(this)} multiple={false} >
              <div>Try dropping some files here, or click to select files to upload.</div>
            </Dropzone>           	    	
  	    		
  		    </div>		
          

        </div>
	    	);
  	} 
}

module.exports = ShowImageQuestion;
