'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _objectAssign = require('object-assign');

var _objectAssign2 = _interopRequireDefault(_objectAssign);

require('../css/fade-in.css');

require('../css/chasing-dots.css');

require('../css/circle.css');

require('../css/cube-grid.css');

require('../css/double-bounce.css');

require('../css/pulse.css');

require('../css/rotating-plane.css');

require('../css/three-bounce.css');

require('../css/wandering-cubes.css');

require('../css/wave.css');

require('../css/wordpress.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } // eslint-disable-line import/no-extraneous-dependencies


var Spinner = function (_React$Component) {
  _inherits(Spinner, _React$Component);

  function Spinner(props) {
    _classCallCheck(this, Spinner);

    var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(Spinner).call(this, props));

    _this.displayName = 'SpinKit';
    return _this;
  }

  _createClass(Spinner, [{
    key: 'render',
    value: function render() {
      var _cx;

      var classes = (0, _classnames2.default)((_cx = {
        'fade-in': !this.props.noFadeIn,
        spinner: this.props.overrideSpinnerClassName === ''
      }, _defineProperty(_cx, this.props.overrideSpinnerClassName, !!this.props.overrideSpinnerClassName), _defineProperty(_cx, this.props.className, !!this.props.className), _cx));

      var props = (0, _objectAssign2.default)({}, this.props);
      delete props.spinnerName;
      delete props.noFadeIn;
      delete props.overrideSpinnerClassName;
      delete props.className;

      var spinnerEl = void 0;
      switch (this.props.spinnerName) {
        case 'double-bounce':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: 'double-bounce ' + classes }),
            _react2.default.createElement('div', { className: 'double-bounce1' }),
            _react2.default.createElement('div', { className: 'double-bounce2' })
          );
          break;
        case 'rotating-plane':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: classes }),
            _react2.default.createElement('div', { className: 'rotating-plane' })
          );
          break;
        case 'wave':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: 'wave ' + classes }),
            _react2.default.createElement('div', { className: 'rect1' }),
            _react2.default.createElement('div', { className: 'rect2' }),
            _react2.default.createElement('div', { className: 'rect3' }),
            _react2.default.createElement('div', { className: 'rect4' }),
            _react2.default.createElement('div', { className: 'rect5' })
          );
          break;
        case 'wandering-cubes':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: 'wandering-cubes ' + classes }),
            _react2.default.createElement('div', { className: 'cube1' }),
            _react2.default.createElement('div', { className: 'cube2' })
          );
          break;
        case 'pulse':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: classes }),
            _react2.default.createElement('div', { className: 'pulse' })
          );
          break;
        case 'chasing-dots':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: classes }),
            _react2.default.createElement(
              'div',
              { className: 'chasing-dots' },
              _react2.default.createElement('div', { className: 'dot1' }),
              _react2.default.createElement('div', { className: 'dot2' })
            )
          );
          break;
        case 'circle':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: 'circle-wrapper ' + classes }),
            _react2.default.createElement('div', { className: 'circle1 circle' }),
            _react2.default.createElement('div', { className: 'circle2 circle' }),
            _react2.default.createElement('div', { className: 'circle3 circle' }),
            _react2.default.createElement('div', { className: 'circle4 circle' }),
            _react2.default.createElement('div', { className: 'circle5 circle' }),
            _react2.default.createElement('div', { className: 'circle6 circle' }),
            _react2.default.createElement('div', { className: 'circle7 circle' }),
            _react2.default.createElement('div', { className: 'circle8 circle' }),
            _react2.default.createElement('div', { className: 'circle9 circle' }),
            _react2.default.createElement('div', { className: 'circle10 circle' }),
            _react2.default.createElement('div', { className: 'circle11 circle' }),
            _react2.default.createElement('div', { className: 'circle12 circle' })
          );
          break;
        case 'cube-grid':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: 'cube-grid ' + classes }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' }),
            _react2.default.createElement('div', { className: 'cube' })
          );
          break;
        case 'wordpress':
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: classes }),
            _react2.default.createElement(
              'div',
              { className: 'wordpress' },
              _react2.default.createElement('div', { className: 'inner-circle' })
            )
          );
          break;
        case 'three-bounce':
        default:
          spinnerEl = _react2.default.createElement(
            'div',
            _extends({}, props, { className: 'three-bounce ' + classes }),
            _react2.default.createElement('div', { className: 'bounce1' }),
            _react2.default.createElement('div', { className: 'bounce2' }),
            _react2.default.createElement('div', { className: 'bounce3' })
          );
      }
      return spinnerEl;
    }
  }]);

  return Spinner;
}(_react2.default.Component);

Spinner.propTypes = {
  spinnerName: _react2.default.PropTypes.string.isRequired,
  noFadeIn: _react2.default.PropTypes.bool,
  overrideSpinnerClassName: _react2.default.PropTypes.string,
  className: _react2.default.PropTypes.string
};

Spinner.defaultProps = {
  spinnerName: 'three-bounce',
  noFadeIn: false,
  overrideSpinnerClassName: ''
};

module.exports = Spinner;