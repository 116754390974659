var React = require('react');

import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import moment from 'moment';
import Async from 'react-promise'
import Avatar from 'material-ui/Avatar';

var firebase = require('firebase');

class TemplateInfoPreview extends React.Component {

	constructor(props){
		super(props); 

	}

	componentDidMount(){
    
  	}


  	render(){
  		
  		var avatarComponentNull = null;

  		if(this.props.templateInfo.img_storage_link){

  			avatarComponentNull = <Async promise={firebase.storage().refFromURL(this.props.templateInfo.img_storage_link).getDownloadURL()} then={(val) => <Avatar src={val} />} catch={(val) => <Avatar src={null} />} />;		    			

  		}else{

  			avatarComponentNull = <Avatar src={null} />;    			
  			
  		}

	    return (  
	    	<Card>  	    	
	    		<CardText>

		    		<div className="text-fullwidth-templateName-preview">
			    		{avatarComponentNull}
			    		<span><h1>{this.props.templateInfo.name}</h1></span>
		    		</div>	  
		    		<div className="text-fullwidth-preview">
		    			{this.props.templateInfo.description}
		    		</div>	
		    		<div className="text-contentwidth-preview">
		    			{this.props.templateInfo.target_google_group}
		    		</div>	 
		    		<br />
		    		<div className="text-contentwidth-preview">
		    			{this.props.templateInfo.type}
		    		</div>				    		

		    		<div className="templateInfo-date-container">

			    		<div className="templateInfo-date-float-left">
			    			
			    			<div className="text-contentwidth-preview">
			    				<span>Data inizio</span>
			    				{ moment(new Date(this.props.templateInfo.start_date)).format("DD/MM/YYYY")}  				    			
				    		</div>
			    						    						    			
						</div>
			    		<div className="templateInfo-date-float-right">
			    		
				    		<div className="text-contentwidth-preview">
			    				<span>Data fine</span>
			    				{ moment(new Date(this.props.templateInfo.end_date)).format("DD/MM/YYYY")}  				    			
				    		</div>
			    		</div>

		    		</div>		    		
				          				        
		    		<div className="text-contentwidth-preview">
		    			COLORE: {this.props.templateInfo.type_color}
		    			<Avatar
					        backgroundColor={this.props.templateInfo.type_color}
					        size={15} 
					        style={{ 'margin-left': '20px'}} />
		    		</div>	

		    		

        		</CardText>
        	</Card>	
	    	);
  	} 
}

module.exports = TemplateInfoPreview;
