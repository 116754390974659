var React = require('react');

var validator = require("email-validator");

var firebase = require('firebase');
var TemplateInfo = require('./TemplateInfo.js');
var Question = require('./Question.js');
var NotificationSystem = require('react-notification-system');
var ReactToastr = require("react-toastr");
var unique = require('array-unique');

var {ToastContainer} = ReactToastr;
var ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);

import Notifications, {notify} from 'react-notify-toast'; 



import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import FlatButton from 'material-ui/FlatButton';
import Q from 'q';

//Icone QuestionTypes
import CheckBoxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank';
import FreeTextIcon from 'material-ui/svg-icons/editor/format-align-left';
import MultipleChoiceIcon from 'material-ui/svg-icons/image/adjust';
import ImagesIcon from 'material-ui/svg-icons/image/image';
import NumberIcon from 'material-ui/svg-icons/image/looks-6';
import RenameIcon from 'material-ui/svg-icons/communication/textsms';
import { browserHistory } from 'react-router';

const questionTypeIcons = [
	{name: "Scelta Singola", leftIcon: <MultipleChoiceIcon />},
	{name: "Testo Libero", leftIcon: <FreeTextIcon />},
	{name: "Valore Numerico", leftIcon: <NumberIcon />},
	{name: "Scelta Multipla", leftIcon: <CheckBoxIcon />},
	{name: "Carica Immagini", leftIcon: <ImagesIcon />}	
];

class TemplateBox extends React.Component {

	constructor(props,context){
		super(props);    
	    this.state = { 
	    	templateInfo: {},
	    	questionsList: [],
	    	questionTypes: [],
	    	buttonNameState: '',
	    	templateImageFile: {},
	    	deletedQuestions:[]		    	
	    };
	    
	}	

	mailUser: {}

	userFullName:{}

	componentWillMount(){		 		
  		let userData = firebase.auth().currentUser;
		this.mailUser = userData.email;
		this.userFullName = userData.displayName;
		
    	var templateId = this.props.templateId;
    	var mode = this.props.mode;    	

    	if(mode === 'create'){
    		this.setState({buttonNameState: 'CREA'});
    	}else if(mode === 'duplicate'){
    		this.setState({buttonNameState: 'DUPLICA'});
    	}

    	this.setTemplateInfoById(templateId,mode);

    	this.setQuestionTypes();

    	this.setQuestions(templateId,mode);
    	console.log(this.ulploadOptions);
    	
  	}

  	componentDidMount(){
  		
  	}

  	addAlert(msgsIn){	

  		var self = this;	
  	
		(function myLoop (i) {          
		   setTimeout(function () {   
		      self.refs.container.error(
			      msgsIn[i-1],
			      "Error", {
			      timeOut: 30000,
			      extendedTimeOut: 10000
			    });	

		      if (--i) myLoop(i);
		   }, 500)
		})(msgsIn.length); 	
  			    
	}

  	createTemplateOnFirebase(){

  		var mode = this.props.mode;	
  		var self = this;
  		var imageArray = [];
  		var newTemplateInfo = {};
  		newTemplateInfo = this.state.templateInfo;

  		newTemplateInfo.creation_date = (new Date()).getTime();
  		newTemplateInfo.last_update_date = (new Date()).getTime();   		
  		newTemplateInfo.owner = this.userFullName;
		this.setState({templateInfo: newTemplateInfo});	
		
		//To Do -- rimuovere ---------//
		for(var k in this.state.questionsList){

			if(this.state.questionsList[k].id_type === '5'){
				
				var stubObj = {
					questionId: k,
					imgObject: this.state.questionsList[k].values[0]
				};

				imageArray.push(stubObj);

				this.state.questionsList[k].values[0] = 'fake_asset_value';
			}

		}
				
    	this.validateTemplateBox(newTemplateInfo,this.state.questionsList).then(function(){
			
			self.createTemplateInstanceOnFirebase(imageArray);

		}).catch(function(errorMessagesArray){	

			self.addAlert(errorMessagesArray);								
					
		});

  	}

  	validateTemplateBox(templateInfoIn,questionsList){
  		
  		var deferredValidation = Q.defer();

		var passValidation = true;
		var errMsgsArray = [];
		
		
		//Template info validation ----------

  		if(!templateInfoIn.name){
  			//check if unique with query
  			passValidation = false;
  			errMsgsArray.push('You must specify Template Name!');
  		}
  		if(templateInfoIn.target_google_group){
  			if(!validator.validate(templateInfoIn.target_google_group)){
	  			passValidation = false;
	  			errMsgsArray.push('Insert valid email address for google group!');	
	  		}
  		}else{
  			passValidation = false;
	  		errMsgsArray.push('Insert Target Google Group!');	
  		}
  		if(!(templateInfoIn.end_date > templateInfoIn.start_date)){
  			passValidation = false;
	  		errMsgsArray.push('Insert valid date range!');	
  		}
  		if(!templateInfoIn.description){
  			//check if unique with query
  			passValidation = false;
  			errMsgsArray.push('You must specify Template Description!');
  		}
  		if(!templateInfoIn.type){
  			//check if unique with query
  			passValidation = false;
  			errMsgsArray.push('You must specify Template Type!');
  		}
	  	
	  	//Question list validation ----------

  		if(!(questionsList.length > 0)){
  			passValidation = false;
  			errMsgsArray.push('No questions!');
  		}

  		for(var k in questionsList){

  			var qType = parseInt(questionsList[k].id_type);
  			var logicId = questionsList[k].idQuestion + 1;
  			var questionsArray = [];
  			questionsArray = questionsList[k].values;

  			//If question has no title
  			if(!questionsList[k].question){
  				passValidation = false;
  				errMsgsArray.push('Question# '+ logicId +' Question field empty');		
  			}
  			//If question type is not selected 
  			if(isNaN(qType)){
  				passValidation = false;
  				errMsgsArray.push('Question# '+ logicId +' Invalid questions type!');		
  			}

  			//Checking duplicates in question values
  			/*if(questionsArray.length > unique(questionsArray).length){
  				passValidation = false;
  				errMsgsArray.push('Question# '+ logicId +' Duplicated Values!');			
				}*/
  			//Validate values if not numeric
  			if(qType === 2){
  				for(var z in questionsArray){
  					if(isNaN(parseInt(questionsArray[z]))){
  						passValidation = false;
  						errMsgsArray.push('Question# '+ logicId +' Not numbers in!');
  						break;
  					}
  				}
  			}

  		}

	  	if(passValidation){
	  		deferredValidation.resolve();
	  	}else{
	  		deferredValidation.reject(errMsgsArray);
	  	}

	  	return deferredValidation.promise;
  	}

  	createTemplateInstanceOnFirebase(imageArray){

  		var self = this;
  		
  		const templateRef = firebase.database().ref('Template');
		let newTemplateRef = templateRef.push(this.state.templateInfo);
		
		const questionsRef = firebase.database().ref('Questions/' + newTemplateRef.key);
		questionsRef.set(this.state.questionsList);	

		const storageRef = firebase.storage().ref('TemplateImages/' + newTemplateRef.key);		
		
		const fileRef = storageRef.child('templateImage.jpg');

		if(this.state.templateImageFile.name){
			fileRef.put(this.state.templateImageFile).then(function(response) {
			 
			//Creates Images on storage				
				newTemplateRef.child('img_storage_link').set("gs://" + response.metadata.bucket + '/' + response.metadata.fullPath);
				
				if(imageArray.length > 0){
					self.uploadImagesQuestionIfPresent(imageArray,newTemplateRef.key);
				}  			  
			  	
			  	self.context.history.push('/dashboard');	
			}).catch(function(error){
				console.log("image upload error", error);
				self.context.history.push('/dashboard');	
			});
		}else{
			//Creates Images on storage 
				if(imageArray.length > 0){
					this.uploadImagesQuestionIfPresent(imageArray,newTemplateRef.key);
				}
				this.context.history.push('/dashboard');	
		}

  	}

  	uploadImagesQuestionIfPresent(objectsIn,templateRefKey){

  		var promiseStorageArray = [];

  		for(var k in objectsIn){
  			//console.log(objectsIn[k])

  			var storageRef = firebase.storage().ref('QuestionsImages/' + templateRefKey + '/' + objectsIn[k].questionId).child(objectsIn[k].imgObject.name);
  			promiseStorageArray.push(storageRef.put(objectsIn[k].imgObject));
  		}

  		Promise.all(promiseStorageArray).then((responses) => {
  	
  			var promiseDatabaseArray = [];		

		  	responses.map((response, index) => {
		  		//update records in questions
		  		//Handling errors
		  		firebase.database().ref("Questions/" + templateRefKey + '/' + objectsIn[index].questionId + "/values/0").set("gs://" + response.a.bucket + '/' + response.a.fullPath);	  		

		  	});

		});

  	}


  	handeLastUpdateSubmit(){
	
		newTemplateInfo.last_update_date = (new Date()).getTime();
		this.setState({templateInfo: newTemplateInfo});		

	}

  	setQuestions(templateId, mode){

  		if(mode === 'duplicate'){

	  		const rootRef = firebase.database().ref('Questions/' + templateId);

	  		rootRef.once('value', snap => {
	  			//console.log(snap.val());
	  			this.setEntryFromFirebaseEntryToQuestionListArray(snap.val());
	  			/*
	  				snap.forEach(function(childSnapshot) {
	        			
	  					this.setEntryFromFirebaseEntryToQuestionListArray(childSnapshot.val());

	    			}.bind(this));  	    	
	    		*/
		    	});	
		}  	
  		
  	}


  	setQuestionTypes(){

  		const rootRef = firebase.database().ref('Question_Types');

  		rootRef.once('value', snap => {

  				this.setEntryFromFirebaseEntryToQuestionTypesArray(snap);		    	
    		
	    	});	

  	}

  	componentGetQuestionTypeIconByName(nameIn){

  		for(var k in questionTypeIcons){
  			if(nameIn === questionTypeIcons[k].name){
  				return questionTypeIcons[k].leftIcon;
  			}  				
  		}
  		//comunque
  		return <RenameIcon />;
  	}

  	setEntryFromFirebaseEntryToQuestionListArray(snapshotData){
    
    	var dataToArray = [];
    	var num=0;
	    snapshotData.forEach(function(childSnapshotData) {
	    	
	        var stubObj = {};
        	stubObj.idQuestion = num++;
					stubObj.questionType = childSnapshotData.id_type; 
					stubObj.id_type = childSnapshotData.id_type; 					
	        stubObj.mandatory = childSnapshotData.mandatory; 
	        stubObj.question = childSnapshotData.question; 
	        stubObj.values = childSnapshotData.values; 	     	
	        dataToArray.push(stubObj);          

	    }.bind(this));      
    
	    
	    this.setState({questionsList: dataToArray});
	 
	}

  	setEntryFromFirebaseEntryToQuestionTypesArray(snapshot){
    
    	var dataToArray = [];

	    snapshot.forEach(function(childSnapshot) {
	        var stubObj = {};

	        var childKey = childSnapshot.key;
	        var childData = childSnapshot.val();
	        stubObj.key = childSnapshot.key;
	        stubObj.name = childSnapshot.val().Name; 
	     	stubObj.leftIcon = this.componentGetQuestionTypeIconByName(stubObj.name);
	        dataToArray.push(stubObj);          

	    }.bind(this));      
    
    
    this.setState({questionTypes: dataToArray});

   }

	setTemplateInfoById(templateId, mode){

		/* 
			Differenzia la logica in base a templateId  e
		*/
		console.log(mode);

		if(mode === 'duplicate'){
			//gestione duplicazione
			console.log("duplico: "+ templateId);
			const rootRef = firebase.database().ref('Template/' + templateId);
				
				let stubObj = {};

			    rootRef.once('value', snap => {			    	    		    	        		       

			        let childKey = snap.key;
		    	    let childData = snap.val();

			        stubObj.id = snap.key;
					/* no image duplication
			        stubObj.img_storage_link = childData.img_storage_link;
			        */
			        stubObj.name = childData.name;
			        stubObj.owner = childData.owner;
			        stubObj.target_google_group = childData.target_google_group;
			        stubObj.description = childData.description;
			        stubObj.creation_date = childData.creation_date;
			        stubObj.last_update_date = childData.last_update_date;
			        stubObj.start_date = childData.start_date;
			        stubObj.end_date = childData.end_date;
			        stubObj.type = childData.type;
			        stubObj.type_color = childData.type_color;

		    	this.setState({templateInfo: stubObj});
    		
	    	});	

		}else if(mode == 'create'){
			//gestione creazione
			let stubObj = {};
			stubObj.id = '';			
	        stubObj.name = 'Template senza titolo';
	        stubObj.owner = '';
	        stubObj.creation_date = (new Date()).getTime();
	        stubObj.last_update_date = (new Date()).getTime();
	        stubObj.start_date = (new Date()).getTime();
	        stubObj.end_date = (new Date()).getTime();
			stubObj.type = '';
			stubObj.type_color = '';

	        this.setState({templateInfo: stubObj});

		}
				    
	}

	handleAddQuestion(){	
		var numItems = this.state.questionsList.length;
		var emptyQuestion = {
			idQuestion: numItems,
			id_type: {},
			mandatory: false,
			question: 'Nuova Domanda',
			values: ['']
		};
		//gestione array
		var newArray = this.state.questionsList;
		newArray.push(emptyQuestion);
		this.setState({questionsList: newArray});
	}

	handleQuestionRemove(indexIn){		

		var newArray = [];
		for(var k in this.state.questionsList){
			if(this.state.questionsList[k].idQuestion === indexIn){
				continue;
			}else{
				console.log("pushing",this.state.questionsList[k]);
				newArray.push(this.state.questionsList[k]);
			}
		}
		
		this.setState({questionsList: newArray});
		console.log(this.state.questionsList);
		//this.forceUpdate();
		
	}

	handleQuestionDuplicate(objToDuplicate){
		var clone = JSON.parse(JSON.stringify(objToDuplicate));
		clone.id_type = clone.questionType;
		clone.idQuestion = this.state.questionsList.length;
		this.setState({questionsList: this.state.questionsList.concat([clone])}); 

	}

	handleTemplateInfoChange(templateInfoChanged){
		this.setState({templateInfo: templateInfoChanged});		
	}		

	handleQuestionNameChangeHandler(questionIndex,val){
		var newArray = this.state.questionsList;
		newArray[questionIndex].question = val;
		this.setState({questionsList: newArray});
		
	}

	handleQuestionValuesChangeHandler(questionIndex,val){
		var newArray = this.state.questionsList;		
		newArray[questionIndex].values = val;
		this.setState({questionsList: newArray});
		
	}

	handleQuestionTypeChangeHandler(questionIndex,val){
		var newArray = this.state.questionsList;
		newArray[questionIndex].id_type = val;
		this.setState({questionsList: newArray});
		
	}

	handleQuestionMandatoryChangeHandler(questionIndex,val){
		var newArray = this.state.questionsList;
		newArray[questionIndex].mandatory = val;
		this.setState({questionsList: newArray});
		
	}

	handleAddTemplateImgage(fileIn){				
		this.setState({templateImageFile: fileIn});		
		console.log("box state:",fileIn);
	}
	
	handleQuestionChange(questionIndex){
		console.log(this.state.questionsList[questionIndex]);
	}

	goBack(){
		this.context.history.push('/dashboard');
	}

  	render(){

  		console.log("question list", this.state.questionsList);

	    return (    	    	
	    		<div className="row">
	    		
	    		<ToastContainer ref="container"
                        toastMessageFactory={ToastMessageFactory}
                        className="toast-top-right" />

	    		<div className="col-md-8">	

	    			<TemplateInfo 
	    				changeHandler={this.handleTemplateInfoChange.bind(this)} 
	    				templateInfo={this.state.templateInfo} 
	    				imageFileAddHandler={this.handleAddTemplateImgage.bind(this)}	    				
	    				key="TemplateInfo"/>

	    			<div id="question_list">
	    			{this.state.questionsList.map ((questionData) => 
	    				<div key={'Question_' + questionData.idQuestion}> 
		    			<Question 
		    				questionData={questionData} 
		    				questionTypes={ this.state.questionTypes }
		    				questionIdInList={questionData.idQuestion}
		    				questionNameChangeHandler={this.handleQuestionNameChangeHandler.bind(this)}
		    				questionValuesChangeHandler={this.handleQuestionValuesChangeHandler.bind(this)}
		    				questionTypeChangeHandler={this.handleQuestionTypeChangeHandler.bind(this)}
		    				questionMandatoryChangeHandler={this.handleQuestionMandatoryChangeHandler.bind(this)}
		    				questionChangeHandler={this.handleQuestionChange.bind(this)}
		    				questionRemoveHandler={this.handleQuestionRemove.bind(this)} 
		    				questionDuplicateHandler={this.handleQuestionDuplicate.bind(this)} 
		    				key={questionData.idQuestion} 
		    				/></div>)
	    				}
	    			</div>
	    			<FloatingActionButton label="" className="pull-right" mini={true} 
	    				backgroundColor="black" onClick={this.handleAddQuestion.bind(this)} >
				      <ContentAdd />

				    </FloatingActionButton>

				</div>
				<div className="col-md-4">
				<div className="templateInfo-date-float-left">
					<div>

					<FlatButton label={"CREA"} 
		    				onClick={this.createTemplateOnFirebase.bind(this)}
		    				backgroundColor="#fff" />

		    		</div>
		    				
		    		<div className="annullaFlatButton">

		    		<FlatButton label={"ANNULLA"}
		    				onClick={this.goBack.bind(this)} 		    				
		    				backgroundColor="#fff" />
		    				
		    		</div>		
		    	</div>	
		    	</div>
	    		</div>
	    	);
  	} 
}

TemplateBox.contextTypes = {
        router: React.PropTypes.object.isRequired,
        history: React.PropTypes.object.isRequired
  };

module.exports = TemplateBox;
