"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function selectRow(_ref) {
  var rows = _ref.rows;
  var _ref$isSelected = _ref.isSelected;
  var isSelected = _ref$isSelected === undefined ? function (row, selectedRowId) {
    return row.id === selectedRowId;
  } : _ref$isSelected;
  var selectedRowId = _ref.selectedRowId;

  var selectedRow = void 0;

  // Reset selected flags and select the given row
  var newRows = rows.map(function (row) {
    var selected = false;

    if (isSelected(row, selectedRowId)) {
      selected = true;

      selectedRow = row;
    }

    return _extends({}, row, {
      selected: selected
    });
  });

  return {
    rows: newRows,
    selectedRow: selectedRow
  };
}

exports.default = selectRow;