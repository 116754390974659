var React = require('react');

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';

const answerTypes = [];

class FreeText extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			questionListValues: []
		}
	
	}

	componentWillMount(){
    	this.setState({questionListValues: this.props.valuesIn});
  	}

  	handleTextFieldChange(newVal){
  		
  		//gestione array
  		//this.setState({questionListValues: []});  
  		var newArray = this.state.questionListValues;
  		newArray[0] = newVal.target.value;
  		
  		this.setState({questionListValues: newArray});  
  		

  		//Settare lo stato del padre
  		this.props.valuesHandler(newArray);	  		
  		
  	}
		
		/*
		    		<TextField type="text" name="FreeTextField"
		    			value={this.state.questionListValues[0]}
		    			fullWidth={true} multiLine={true} 
		    			onChange={(newVal) => {
		    				

								this.handleTextFieldChange(newVal);
							}
						}
						/> 
						*/
	
  	render(){

	    return (  
	    	<div>
	    	
		    			
		    	
        	</div>
	    	);
  	} 
}

module.exports = FreeText;
