var React = require('react');
var ReactDOM = require('react-dom');

import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import DuplicateIcon from 'material-ui/svg-icons/image/filter-none';
import Toggle from 'material-ui/Toggle';

import CheckBoxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank';
import FreeTextIcon from 'material-ui/svg-icons/editor/format-align-left';
import MultipleChoiceIcon from 'material-ui/svg-icons/image/adjust';
import ImagesIcon from 'material-ui/svg-icons/image/image';
import NumberIcon from 'material-ui/svg-icons/image/looks-6';
import RenameIcon from 'material-ui/svg-icons/communication/textsms';


var QuestionTypePreview = require('./QuestionTypePreview.js');

const answerTypes = [];

class QuestionPreview extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			questionType: null, //idType su firebase
			question:'',
			values: [], //values su firebase
			mandatory: false			
		};

	}

	componentWillMount(){
		this.setState({question: this.props.questionData.question});
		this.setState({questionType: this.props.questionData.id_type});
		this.setState({values: this.props.questionData.values});
		this.setState({mandatory: this.props.questionData.mandatory});
	}


  	render(){

	    return (  
	    	<div className="single-question">

		    	<Card>  

		    	
		    		<CardText>
		    			
		    			<div className="text-fullwidth-preview">
		    				{this.state.question}
		    			</div>				    		
			    		
			    		<QuestionTypePreview 
			    			questionTypeValue={this.state.questionType} 
		    				valuesIn={this.state.values} />
		    			
	        		</CardText>
	        		
	        		

	        			
	        		<br />
	        		<br />
	        		}
	        	</Card>	
        	</div>
	    	);
  	} 
}

module.exports = QuestionPreview;
