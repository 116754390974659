import React, { Component } from 'react';
import * as firebase from 'firebase';
import * as config from '../../firebase.config.js';

function isAlreadyLogged(nextState, replace) {	

    if(null !== firebase.auth().currentUser) {

        replace({
          pathname: '/dashboard',
          state: { nextPathname: nextState.location.pathname }
        })
    }
}

module.exports = isAlreadyLogged;