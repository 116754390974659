'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function mergeClassNames(a, b) {
  if (a && b) {
    return a + ' ' + b;
  }

  // Either a or b at this point
  return (a || '') + (b || '');
}

exports.default = mergeClassNames;