'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactabularUtils = require('reactabular-utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderRow = function HeaderRow(_ref) {
  var row = _ref.row;
  var components = _ref.components;
  return _react2.default.createElement(components.row, {}, row.map(function (_ref2, j) {
    var column = _ref2.column;
    var _ref2$header = _ref2.header;
    var header = _ref2$header === undefined ? {} : _ref2$header;
    var _ref2$props = _ref2.props;
    var props = _ref2$props === undefined ? {} : _ref2$props;
    var label = header.label;
    var _header$transforms = header.transforms;
    var transforms = _header$transforms === undefined ? [] : _header$transforms;
    var _header$format = header.format;
    var format = _header$format === undefined ? function (a) {
      return a;
    } : _header$format;

    var extraParameters = {
      columnIndex: j,
      column: column,
      property: column && column.property // TODO: test that this is passed properly
    };
    var transformedProps = (0, _reactabularUtils.evaluateTransforms)(transforms, label, extraParameters);

    if (!transformedProps) {
      console.warn('Table.Header - Failed to receive a transformed result'); // eslint-disable-line max-len, no-console
    }

    return _react2.default.createElement(components.cell, _extends({
      key: j + '-header'
    }, (0, _reactabularUtils.mergePropPair)(props, transformedProps)), transformedProps.children || format(label, extraParameters));
  }));
};
process.env.NODE_ENV !== "production" ? HeaderRow.propTypes = {
  row: _react2.default.PropTypes.arrayOf(_react2.default.PropTypes.object),
  components: _react2.default.PropTypes.object
} : void 0;

exports.default = HeaderRow;