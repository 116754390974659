'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _reactabularUtils = require('reactabular-utils');

var _strategies = require('./strategies');

var _strategies2 = _interopRequireDefault(_strategies);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var sort = function sort() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _ref$event = _ref.event;
  var event = _ref$event === undefined ? 'onClick' : _ref$event;
  var _ref$getSortingColumn = _ref.getSortingColumns;
  var getSortingColumns = _ref$getSortingColumn === undefined ? function () {
    return [];
  } : _ref$getSortingColumn;
  var _ref$onSort = _ref.onSort;
  var onSort = _ref$onSort === undefined ? function () {} : _ref$onSort;
  var _ref$strategy = _ref.strategy;
  var strategy = _ref$strategy === undefined ? _strategies2.default.byIndex : _ref$strategy;
  return function (_value, extra) {
    var _ref2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var className = _ref2.className;

    var props = _objectWithoutProperties(_ref2, ['className']);

    var sortingColumns = getSortingColumns();
    var field = extra[strategy.fieldName];
    var headerClass = 'sort sort-none';

    // Check against undefined to allow zero
    if (sortingColumns[field] !== undefined) {
      headerClass = 'sort sort-' + sortingColumns[field].direction;
    }

    return _extends({}, props, _defineProperty({
      className: (0, _reactabularUtils.mergeClassNames)(className, headerClass)
    }, event, function () {
      return onSort(field);
    }));
  };
};

exports.default = sort;