var React = require('react');

import { Router, Route, hashHistory, IndexRoute, browserHistory} from 'react-router'
var Main = require('../components/Main');
var Login = require("../components/login-register/Login");
var Logout = require('../components/login-register/Logout');
var Dashboard = require('../components/secure/Dashboard');
var Privacy = require('../components/secure/Privacy');
var NotificationsDashboard = require('../components/secure/NotificationsDashboard');
var TemplateContainer = require('../components/secure/TemplateContainer');
var requireAuth = require('../utils/authenticated');
var isAlreadyLogged = require('../utils/isAlreadyLogged');

var routes = (
  <Router history={ browserHistory }>
    <Route path='/' component={Main}>
    <IndexRoute component={Login} />
    
	    <Route path="login" component={Login} />      

	    <Route path="privacy" component={Privacy} />      
	      
	    <Route path="dashboard" component={Dashboard} onEnter={requireAuth} />

	    <Route path="notifications" component={NotificationsDashboard} onEnter={requireAuth} />
	    
	  	<Route path="template/:templateId/:mode" component={TemplateContainer}/>
	      
    </Route>
  </Router>
);

module.exports = routes;
